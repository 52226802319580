<template>
  <svg viewBox="0 0 457.53 446.19">
    <path
      d="m364.27,22.99c-28.82-28.82-75.55-28.82-104.38,0,0,0,0,0,0,0l-43.23,43.23c-13.16,13.09-20.88,30.68-21.62,49.22-1.68,30.85-14.91,59.93-37.06,81.46l-3.95,3.95c-22.75,22.74-22.76,59.61-.02,82.37,0,0,.02.02.02.02l21.99,21.99c22.76,22.73,59.63,22.73,82.39,0l3.95-3.95c21.54-22.14,50.62-35.37,81.46-37.06,18.38-.75,35.81-8.35,48.85-21.31l43.23-43.23c28.82-28.82,28.82-75.55,0-104.38,0,0,0,0,0,0l-71.64-72.32Zm-114.88,265.14l-3.95,3.95c-8.6,8.58-20.67,12.75-32.73,11.3l1.17-1.17c3.74-3.49,3.95-9.35.46-13.09s-9.35-3.95-13.09-.46c-.16.15-.31.3-.46.46l-6.86,6.86c-1.66-1.19-3.23-2.52-4.69-3.95l-4.45-4.45,7.23-7.23c3.49-3.74,3.28-9.61-.46-13.09-3.56-3.32-9.07-3.32-12.63,0l-7.23,7.23-4.45-4.45c-1.43-1.44-2.75-2.99-3.95-4.63l7.04-6.79c3.49-3.74,3.28-9.61-.46-13.09-3.56-3.32-9.07-3.32-12.63,0l-1.17,1.17c-.09-1.54-.09-3.09,0-4.63-.02-10.55,4.18-20.67,11.67-28.1l3.95-3.95c2.96-2.96,5.81-6.18,8.46-9.26l78.56,78.93c-3.21,2.66-6.3,5.5-9.33,8.46Zm174.17-101.78l-43.23,43.23c-9.83,9.96-23.07,15.82-37.06,16.37-24.79,1.25-48.79,9.14-69.48,22.85l-82.95-83.19c13.66-20.69,21.51-44.66,22.73-69.42.57-13.82,6.33-26.92,16.12-36.69l43.23-43.23c21.65-21.39,56.48-21.39,78.13,0l72.51,71.95c21.39,21.65,21.39,56.48,0,78.13Z"
    />
    <path
      d="m268.35,78.14c-3.62,3.63-3.6,9.51.03,13.12,3.63,3.62,9.51,3.6,13.12-.03,16.01-15.97,41.92-15.97,57.93,0,3.74,3.49,9.61,3.28,13.09-.46,3.32-3.56,3.32-9.07,0-12.63-23.25-23.24-60.93-23.24-84.18,0Z"
    />
    <circle cx="100.3" cy="292.45" r="8.89" />
    <circle cx="175.53" cy="341.37" r="8.89" />
    <circle cx="142.74" cy="313.96" r="8.89" />
    <circle cx="83.69" cy="341.37" r="8.89" />
    <circle
      cx="131.55"
      cy="352.98"
      r="8.89"
      transform="translate(-229.86 459.18) rotate(-85.93)"
    />
    <circle cx="27.73" cy="337.54" r="8.89" />
    <circle cx="57.81" cy="382.69" r="8.89" />
    <circle
      cx="111.48"
      cy="391.55"
      r="8.89"
      transform="translate(-291.96 337.03) rotate(-66.41)"
    />
    <circle
      cx="165.21"
      cy="391.59"
      r="8.89"
      transform="translate(-91.43 52.81) rotate(-14.27)"
    />
    <circle
      cx="133.84"
      cy="435.92"
      r="8.89"
      transform="translate(-310.48 538.52) rotate(-85.93)"
    />
    <circle
      cx="62.83"
      cy="435.91"
      r="8.89"
      transform="translate(-336.26 249.66) rotate(-56.78)"
    />
    <circle cx="8.89" cy="385.4" r="8.89" />
  </svg>
</template>
<script setup></script>
