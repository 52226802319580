<template>
  <svg viewBox="0 0 457.53 446.19">
    <path
      d="m286.31,184.65c.21-1.17.53-3.98,1.23-6.7,4.63-18.12,9.26-36.24,14.04-54.32,2.57-9.73,9.13-15.45,19.03-16.48,9.95-1.04,17.45,3.4,21.98,12.32,7.66,15.08,15.4,30.13,22.61,45.42,7.33,15.52.92,31.64-14.97,37.93-9.99,3.95-20.09,7.85-30.46,10.54-17.58,4.56-33.77-8.91-33.45-28.7Zm37.96-50.36l-1.74-.12c-4.34,17.34-8.92,34.62-12.84,52.06-.97,4.3,2.42,5.77,6.5,4.34,8.21-2.88,16.43-5.73,24.6-8.74,3.66-1.35,5.34-3.71,3.29-7.77-6.67-13.22-13.22-26.5-19.82-39.76Z"
    />
    <path
      d="m404.05,178.13c8.2-.03,15.1,3.11,20.3,9.3,6.59,7.85,13.08,15.83,19.11,24.12,9.86,13.56,6.87,30.4-7.23,39.58-14.39,9.37-29.1,18.26-43.82,27.1-8.58,5.16-17.33,4.63-25.12-1.64-7.48-6.01-10.15-14.07-7.3-23.41,5.76-18.86,11.48-37.74,17.71-56.45,4.01-12.04,14.1-18.74,26.35-18.61Zm-21.11,78.9c14.47-8.94,27.08-16.72,39.69-24.51,3.53-2.18,4.29-4.9,1.61-8.28-5.42-6.83-10.66-13.81-16.34-20.41-3.64-4.23-7.21-3.41-8.99,2.07-5.26,16.27-10.22,32.63-15.98,51.12Z"
    />
    <path
      d="m181.77,331.16c.94-17.67,1.87-35.34,2.83-53.01.63-11.66,5.5-20.76,16.41-25.77,10.63-4.88,20.47-2.78,29.53,4.17,6.51,4.99,13.09,9.9,19.55,14.96,15.63,12.26,17.03,29.56,3.43,43.88-10.6,11.16-21.36,22.15-32.14,33.14-8.46,8.63-17.23,10.9-26.32,7.04-9.45-4.01-14.02-12.3-13.3-24.41Zm23.34-3.19c10.47-9.5,21.9-19.63,32.88-30.23,1-.96-.06-5.95-1.67-7.38-6.12-5.42-12.77-10.27-19.4-15.08-5.69-4.13-8.8-2.56-9.23,4.7-1,16.61-1.8,33.23-2.58,47.99Z"
    />
    <path
      d="m203.83,379.48c16.54-6.3,33.07-12.6,49.61-18.88,10.92-4.14,21.21-3.38,30.21,4.56,8.77,7.74,10.83,17.59,8.15,28.69-1.93,7.98-3.74,15.98-5.76,23.93-4.88,19.26-20.12,27.55-38.73,20.91-14.49-5.17-28.91-10.56-43.32-15.96-11.31-4.24-16.94-11.34-17.1-21.21-.17-10.26,5.56-17.81,16.93-22.05Zm12.38,20.05c12.92,5.72,26.82,12.07,40.96,17.81,1.29.52,5.42-2.47,6.07-4.51,2.47-7.79,4.21-15.83,5.93-23.85,1.47-6.88-1.23-9.08-8.04-6.54-15.59,5.82-31.11,11.82-44.92,17.08Z"
    />
    <path
      d="m425.1,347.54c-3.02,17.44-6.02,34.87-9.05,52.3-2,11.51-7.91,19.97-19.33,23.66-11.13,3.6-20.65.35-28.84-7.62-5.88-5.72-11.84-11.37-17.65-17.16-14.08-14.02-13.44-31.36,1.76-43.98,11.84-9.83,23.82-19.49,35.82-29.13,9.41-7.57,18.39-8.79,26.97-3.9,8.91,5.09,12.48,13.87,10.33,25.81Zm-23.56.42c-11.51,8.2-24.06,16.91-36.21,26.15-1.1.84-.64,5.92.79,7.52,5.44,6.1,11.47,11.7,17.49,17.26,5.17,4.77,8.44,3.57,9.72-3.58,2.95-16.38,5.7-32.79,8.21-47.35Z"
    />
    <path
      d="m142.58,334.83c4.48,17.12,8.97,34.23,13.43,51.36,2.95,11.3,1.08,21.45-7.79,29.54-8.64,7.88-18.65,8.88-29.4,5.02-7.72-2.77-15.48-5.44-23.17-8.3-18.62-6.92-25.22-22.97-16.62-40.75,6.7-13.85,13.61-27.61,20.53-41.35,5.43-10.79,13.1-15.62,22.93-14.72,10.22.94,17.1,7.45,20.1,19.21Zm-21.26,10.15c-7.08,12.23-14.89,25.36-22.12,38.8-.66,1.22,1.87,5.65,3.83,6.52,7.48,3.3,15.29,5.89,23.07,8.46,6.68,2.2,9.16-.24,7.37-7.29-4.1-16.13-8.4-32.2-12.15-46.5Z"
    />
    <path
      d="m61.95,105.93c-9.5-1.16-20.28-1.96-30.85-3.87-15.71-2.85-25.21-15.71-22.27-31.44,3.39-18.1,7.91-36.03,12.73-53.81,2.23-8.23,8.65-13.15,17.39-14.31,8.95-1.19,16.84,1.5,21.19,9.36,10.3,18.59,20.63,37.25,29.39,56.58,8.23,18.15-6.38,37.94-27.59,37.49ZM43.64,29.36c-.59.17-1.17.34-1.76.51-3.35,14.09-6.93,28.14-9.8,42.33-.43,2.13,2.17,6.76,3.99,7.12,8.74,1.73,17.67,2.66,26.57,3.34,4.98.38,7.41-2.32,4.89-7.25-7.86-15.4-15.92-30.71-23.9-46.06Z"
    />
    <path
      d="m162.87,267.66c-.19,17.39-8.96,28.9-21.66,30.9-18.21,2.87-36.56,5.03-54.91,6.86-8.91.89-16-3.41-20.27-11.35-4.41-8.2-4.12-16.72,1.98-23.76,13.59-15.7,27.28-31.36,41.81-46.18,12.61-12.86,35.2-9.59,42.32,6.7,5.67,12.96,8.07,27.34,10.73,36.83Zm-74.76,14.51c17.27-2.14,32.34-3.8,47.31-6.14,1.62-.25,4.04-4.63,3.69-6.69-1.41-8.28-3.61-16.44-5.83-24.56-1.54-5.61-5.02-6.8-8.78-2.76-11.77,12.66-23.24,25.6-36.39,40.15Z"
    />
    <path
      d="m256.93,187.77c-.03,18.81-9.92,29.92-24.99,31.97-16.51,2.25-33.05,4.25-49.61,6.07-9.56,1.05-17.26-2.7-21.92-11.13-4.64-8.38-4.3-17.08,2.09-24.4,13.49-15.46,26.98-30.95,41.29-45.63,12.83-13.17,35.65-9.81,42.8,6.88,5.48,12.77,7.78,26.91,10.34,36.25Zm-72.7,12.59c.34.58.68,1.16,1.01,1.74,14.43-1.74,28.9-3.25,43.25-5.51,1.99-.31,5.11-4.61,4.79-6.57-1.45-8.78-3.72-17.46-6.2-26.03-1.28-4.42-4.71-5.71-8.09-2.04-11.71,12.69-23.19,25.6-34.75,38.42Z"
    />
    <path
      d="m427,104.79c.92,13.3-3.04,21.21-12.01,25.35-9.47,4.36-17.98,2.28-25.49-4.58-13.63-12.45-27.18-24.99-40.82-37.43-8.29-7.57-11.7-16.89-9.08-27.75,2.71-11.22,10.36-17.95,21.55-20.63,8.22-1.97,16.45-3.85,24.69-5.75,18.77-4.33,33.41,5.45,35.94,24.5,2.12,16,3.64,32.08,5.23,46.29Zm-22.95,2.73c-1.91-17.04-3.37-31.98-5.51-46.83-.26-1.78-4.45-4.58-6.38-4.26-8.8,1.46-17.51,3.67-26.13,6.03-4.58,1.25-5.71,4.73-2.19,8.05,12.76,12.03,25.79,23.78,40.21,37.01Z"
    />
    <path
      d="m312.63,82.6c.15,17.52-7.91,28.98-20.83,31.18-18.67,3.18-37.53,5.45-56.4,7.15-8.8.8-15.85-4.05-19.78-12.16-3.75-7.74-3.67-15.95,1.97-22.49,13.89-16.11,27.83-32.27,42.86-47.29,12.65-12.64,35.33-8.41,42.13,7.97,5.23,12.63,7.56,26.46,10.05,35.63Zm-72.74,13.03c.31.53.63,1.06.94,1.6,14.46-1.73,28.92-3.46,43.39-5.2,4.13-.5,5.95-2.84,5.01-6.89-2.02-8.71-3.98-17.45-6.41-26.06-1.31-4.64-4.82-5.5-8.09-1.97-11.75,12.71-23.25,25.66-34.84,38.51Z"
    />
    <path
      d="m198.33,94.73c.87,12.81-2.94,20.27-11.57,24.63-8.58,4.33-17.13,3.34-24.36-3.03-15-13.22-30.1-26.36-44.43-40.29-15.23-14.81-8.95-39.28,11.37-45.56,9.77-3.01,19.75-5.5,29.78-7.43,16.98-3.26,30.55,6.68,33.21,23.7.47,3.04.89,6.08,1.26,9.14,1.71,13.98,3.4,27.97,4.72,38.85Zm-22.96,2.32c-2.05-16.7-3.86-31.16-5.58-45.62-.59-5.03-3.34-6.54-7.98-5.39-8.19,2.03-16.43,3.89-24.56,6.15-5.29,1.47-5.77,5.05-2.03,8.51,12.73,11.78,25.7,23.31,40.15,36.35Z"
    />
    <path
      d="m352.06,325.54c.09,18.77-16.56,32.09-34,27.49-10.39-2.74-20.67-6.06-30.71-9.86-14.44-5.46-21.53-21.14-15.58-35.33,6.93-16.53,14.62-32.75,22.39-48.91,4.05-8.42,11.43-12.39,20.74-11.84,9.31.55,16.36,5.25,19.14,14.21,6.23,20.05,11.86,40.29,17.67,60.48.42,1.45.29,3.05.35,3.77Zm-38.66-53.37c-7.1,15.31-13.52,28.61-19.26,42.2-.78,1.86,1.21,6.76,3.06,7.53,8.23,3.43,16.83,6.03,25.39,8.59,4.09,1.22,7.2-.99,6.09-5.13-4.61-17.18-9.77-34.21-15.28-53.19Z"
    />
    <path
      d="m82.42,117.61c6.1,3.32,13.12,5.62,18.12,10.15,12.34,11.17,23.85,23.27,35.5,35.17,7.39,7.55,8.99,16.74,4.96,25.39-4.1,8.79-12.69,13.33-23.42,12.54-18.14-1.34-36.29-2.68-54.45-3.88-11.16-.74-19.8-5.63-24.57-15.76-4.88-10.37-3.5-20.46,3.66-29.58,5.86-7.46,11.65-14.97,17.65-22.3,5.52-6.75,12.76-10.09,21.55-9.89.33-.62.66-1.24,1-1.85Zm33.18,59.81c.24-.64.47-1.28.71-1.92-10.32-10.24-20.5-20.63-31.12-30.53-1.48-1.38-6.47-1.58-7.61-.31-6.16,6.8-11.83,14.08-17.22,21.53-2.74,3.79-1.38,7.17,3.51,7.57,17.23,1.38,34.49,2.47,51.73,3.67Z"
    />
  </svg>
</template>
<script setup></script>
