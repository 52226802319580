<template>
  <svg viewBox="0 0 457.53 446.19">
    <path
      class="cls-1"
      d="m235.06,239.46v180.68c0,11.57-8.19,21.22-19.09,23.46-.01,0-.02,0-.03,0-1.56.32-3.18.49-4.83.49-1.46,0-2.88-.13-4.27-.39-11.19-2-19.69-11.79-19.69-23.57v-122.85L47.58,436.88c-4.37,4.37-10.31,6.83-16.49,6.83h0c-13.38,0-24.22-10.84-24.22-24.22h0c0-6.19,2.46-12.12,6.83-16.49l139.58-139.58H55.3s-24.88,0-24.88,0c-11.77,0-21.56-8.49-23.56-19.68-.26-1.39-.39-2.81-.39-4.27,0-1.64.17-3.25.48-4.8,0-.01,0-.02.01-.03,2.23-10.91,11.88-19.13,23.46-19.13h180.69c6.62,0,12.6,2.69,16.94,7.02,4.33,4.34,7.01,10.32,7.01,16.94Z"
    />
    <path
      class="cls-1"
      d="m449.63,205.58c0,1.68-.17,3.32-.51,4.91v.02c-2.27,10.86-11.91,19.02-23.45,19.02h-180.68c-6.62,0-12.61-2.67-16.94-7.01-4.34-4.33-7.02-10.32-7.02-16.94V24.89c0-11.55,8.18-21.2,19.07-23.45.02-.01.04-.01.06-.01,1.56-.32,3.18-.49,4.83-.49,1.46,0,2.88.13,4.27.39,11.19,2,19.68,11.79,19.68,23.57v122.85L408.54,8.16c4.37-4.37,10.29-6.82,16.47-6.82h0c13.38,0,24.22,10.85,24.22,24.22h0c0,6.19-2.46,12.13-6.84,16.51l-139.57,139.56h97.97s24.88.01,24.88.01c11.75,0,21.54,8.46,23.56,19.63.27,1.4.4,2.85.4,4.32Z"
    />
  </svg>
</template>
<script setup></script>
