<template>
  <svg viewBox="0 0 457.53 446.19">
    <path
      d="m179.68,20.52c85.43-.37,155.35,69.23,155.6,154.88.25,85.72-69.4,155.59-155.22,155.71-86.47.12-155.2-68.49-155.41-155.13-.21-86.46,68.22-155.09,155.03-155.46Zm.47,45.78c-60.01-.1-109.74,49.54-109.71,109.52.03,60,49.92,109.78,109.74,109.51,59.94-.27,109.32-49.75,109.31-109.54,0-59.8-49.53-109.39-109.34-109.49Z"
    />
    <path
      d="m326.99,334.08c-6.67,6.61-10.38,13.54-8.28,21.91,1.09,4.35,3.2,9.03,6.27,12.19,17.56,18.1,35.48,35.86,53.4,53.6,8.73,8.64,21.01,8.79,30.01.38,6.14-5.74,12.1-11.69,17.82-17.84,7.85-8.46,8.11-21.42.05-29.67-18.1-18.54-36.42-36.87-54.98-54.95-8.05-7.84-20.38-7.64-29.1-.48-1.06.87-2.07,1.81-4.04,3.54-2.61-2.98-5.09-6.09-7.85-8.92-6.16-6.32-12.42-12.53-18.7-18.72-4.66-4.59-9.58-4.87-13.41-.89-3.75,3.89-3.45,8.62,1,13.12,7.95,8.04,15.95,16.01,23.99,23.96,1.07,1.05,2.44,1.8,3.81,2.78Z"
    />
  </svg>
</template>
<script setup></script>
