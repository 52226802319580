<template>
  <div class="login-container">
    <div class="box">
      <img src="/img/background.jpeg" />
      <div class="loading-container" v-if="loading">
        <div class="spinner-border text-primary" role="status"></div>
      </div>
      <div v-if="!loading" class="input-container">
        <div class="inputs">
          <div>
            <h2>وارد شوید</h2>
            <label class="form-label"></label>
            <input
              class="form-control"
              v-model="user"
              placeholder="نام کاربری"
            />
            <label class="form-label"></label>
            <input
              class="form-control"
              v-model="pass"
              placeholder="کلمه عبور"
            />
          </div>
          <button class="btn btn-primary" @click="login">ورود</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import router from "@/router";
import axios from "axios";
import { ref } from "vue";
import { useToast } from "vue-toastification";
const toast = useToast();
const loading = ref(false);
const user = ref("");
const pass = ref("");

const login = async () => {
  try {
    loading.value = true;

    const { data } = await axios.post(
      `${process.env.VUE_APP_BASE_API}/api/login`,
      {
        user: user.value,
        pass: pass.value,
      }
    );
    toast.success("خوش آمدید .", {
      position: "top-right",
      timeout: 2000,
    });
    localStorage.setItem("token", data.token);
    localStorage.setItem("id", data.id);
    loading.value = false;
    router.push("/admin");
  } catch (err) {
    toast.error(err.message, {
      position: "top-right",
      timeout: 2000,
    });
    console.log(err);
    loading.value = false;
  }
};
</script>
<style lang="scss" scoped>
.login-container {
  direction: rtl;
  width: 100vw;
  height: 100vh;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  .box {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 450px;
    height: 700px;
    border-radius: 15px;
    .input-container {
      padding: 2rem;
      flex: 3;
      display: flex;
    }
    .inputs {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
    div {
      width: 100%;
    }
    button {
      width: 100%;
    }
    img {
      border-radius: 15px 15px 0 0;
      width: 100%;
    }
  }
  .loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .spinner-border {
      width: 50px !important;
      height: 50px;
    }
  }
}
@media only screen and (max-width: 600px) {
  .login-container {
    .box {
      width: 250px;
      height: 500px;
      .input-container {
        padding: 2rem 1rem;
        .inputs {
          .form-control {
            font-size: 13px;
            height: 30px;
          }
          h2 {
            font-size: 1.2rem;
          }
        }
      }
    }
  }
}
</style>
