<template>
  <svg viewBox="0 0 457.53 446.19">
    <path
      d="m293.49,392.4H35.71c-7.85-.01-14.21-6.37-14.22-14.22v-40.12c-.03-30,19.16-56.64,47.63-66.1l83.97-27.97c24.5-8.26,51.03-8.26,75.53,0l62.36,20.74c4.19,1.3,8.64-1.05,9.94-5.24,1.26-4.07-.91-8.41-4.93-9.83l-62.35-20.74c-.34-.11-.68-.19-1.01-.3,96.47-45.32,66.3-189.79-41.76-191.38-55.37.06-100.24,44.93-100.3,100.3-1,41.47,27.22,75.53,57.51,91.37l-83.97,27.97c-34.96,11.62-58.54,44.33-58.5,81.17v40.12c.02,16.62,13.49,30.09,30.11,30.11h257.78c4.39-.09,7.87-3.72,7.78-8.11-.09-4.26-3.52-7.69-7.78-7.78ZM106.45,137.54c.05-46.6,37.82-84.36,84.41-84.41,111.53,4.58,112.18,163.26.76,168.79-46.67.37-84.81-37.17-85.18-83.85,0-.18,0-.35,0-.53Z"
    />
    <path
      d="m450.49,306.07c.16,10.32-8.17,18.77-18.49,18.77h-55.61c-5.17,0-9.39,4.23-9.39,9.39l.42,55.42c.08,10.18-8.08,18.5-18.26,18.63h-.07c-10.3.13-18.72-8.18-18.72-18.49v-55.56c0-5.17-4.23-9.39-9.39-9.39l-55.37.42c-10.16.08-18.48-8.05-18.63-18.21v-.07c-.16-10.32,8.17-18.77,18.49-18.77h55.52c5.17,0,9.39-4.23,9.39-9.39l-.35-55.44c-.06-10.17,8.09-18.48,18.26-18.61h0c10.3-.13,18.72,8.19,18.72,18.49v55.56c0,5.17,4.23,9.39,9.39,9.39l55.47-.42c10.16-.08,18.48,8.05,18.63,18.21v.07Z"
    />
  </svg>
</template>
<script setup></script>
