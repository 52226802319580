<template>
  <svg viewBox="0 0 457.53 446.19">
    <path
      d="m98.71,167.08c-51.33,0-93.1,41.76-93.1,93.1s41.76,93.1,93.1,93.1,93.1-41.76,93.1-93.1-41.76-93.1-93.1-93.1Zm0,169.41c-42.08,0-76.32-34.23-76.32-76.32s34.24-76.31,76.32-76.31,76.32,34.23,76.32,76.32-34.23,76.31-76.32,76.31Z"
    />
    <path
      d="m95.74,196.88c-31.79,0-57.66,25.87-57.66,57.67,0,4.63,3.76,8.39,8.39,8.39s8.39-3.76,8.39-8.39c.02-22.57,18.31-40.86,40.88-40.88,4.64,0,8.39-3.76,8.39-8.39s-3.76-8.39-8.39-8.39Zm261.65-29.8c-51.33,0-93.1,41.76-93.1,93.1s41.76,93.1,93.1,93.1,93.1-41.76,93.1-93.1-41.76-93.1-93.1-93.1Zm0,169.41c-42.08,0-76.32-34.23-76.32-76.32s34.23-76.32,76.32-76.32,76.32,34.23,76.32,76.32-34.24,76.32-76.32,76.32Z"
    />
    <path
      d="m354.42,196.88c-31.79,0-57.66,25.87-57.66,57.67,0,4.63,3.76,8.39,8.39,8.39s8.39-3.76,8.39-8.39c.02-22.57,18.31-40.86,40.88-40.88,4.64,0,8.39-3.76,8.39-8.39s-3.76-8.39-8.39-8.39Z"
    />
    <path
      d="m357.37,148.17c8.04,0,15.81.85,23.38,2.46-8.56-15.21-24.66-24.62-42.11-24.61h-64.45c-6.1-20.35-24.84-34.28-46.09-34.26-21.28-.05-40.06,13.88-46.18,34.26h-64.54c-18.07,0-33.78,9.94-42.12,24.61,7.72-1.64,15.58-2.46,23.47-2.46,61.8,0,112.05,50.25,112.05,112.05,0,14.29-2.75,27.92-7.67,40.5h49.87c-5.06-12.9-7.67-26.64-7.67-40.5,0-61.8,50.25-112.05,112.05-112.05Zm-129.37,11.45c-10.89-.03-19.7-8.88-19.69-19.78,0-10.86,8.79-19.68,19.66-19.69h.03c10.86,0,19.68,8.79,19.69,19.66v.03c.02,10.89-8.79,19.74-19.69,19.78Z"
    />
  </svg>
</template>
<script setup></script>
