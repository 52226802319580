<template>
  <svg viewBox="0 0 457.53 446.19">
    <path
      d="m417.97,1.37H39.56C21.59,1.37,7.04,15.91,7.04,33.89v94.6c0,17.97,14.54,32.52,32.52,32.52h26.61v14.78c0,11.35,9.34,20.69,20.69,20.69h97.56v26.61c0,10.41,7.69,18.92,17.74,20.34v18.09h-115.3c-4.85,0-8.87,4.02-8.87,8.87s4.02,8.87,8.87,8.87h14.78v14.78c0,11.35,9.34,20.69,20.69,20.69h212.85c11.35,0,20.69-9.34,20.69-20.69v-14.78h14.78c4.85,0,8.87-4.02,8.87-8.87s-4.02-8.87-8.87-8.87h-115.3v-18.09c10.05-1.42,17.74-9.93,17.74-20.34v-26.61h97.56c11.35,0,20.69-9.34,20.69-20.69v-14.78h26.61c17.97,0,32.52-14.55,32.52-32.52V33.89c0-17.97-14.54-32.52-32.52-32.52Zm-79.82,277.89v14.78c0,1.66-1.3,2.96-2.96,2.96H122.34c-1.66,0-2.96-1.3-2.96-2.96v-14.78h218.77Zm-118.25-17.74v-17.74h17.74v17.74h-17.74Zm35.48-38.43c0,1.66-1.3,2.96-2.96,2.96h-47.3c-1.66,0-2.96-1.3-2.96-2.96v-26.61h53.21v26.61Zm118.25-47.3c0,1.66-1.3,2.96-2.96,2.96H86.86c-1.66,0-2.96-1.3-2.96-2.96v-14.78h289.72v14.78Zm59.13-47.3c0,8.16-6.62,14.78-14.78,14.78H39.56c-8.16,0-14.78-6.62-14.78-14.78V33.89c0-8.16,6.62-14.78,14.78-14.78h378.41c8.16,0,14.78,6.62,14.78,14.78v94.6Z"
    />
    <path
      d="m193.29,36.85H51.39c-4.9,0-8.87,3.97-8.87,8.87v70.95c0,4.9,3.97,8.87,8.87,8.87h141.9c4.9,0,8.87-3.97,8.87-8.87V45.71c0-4.9-3.97-8.87-8.87-8.87Zm-8.87,70.95H60.25v-53.21h124.17v53.21Z"
    />
    <path
      d="m441.62,427.08h-168.51v-29.56h56.17c14.66,0,26.61-11.94,26.61-26.61s-11.94-26.61-26.61-26.61h-62.08c-4.85,0-8.87,4.02-8.87,8.87s4.02,8.87,8.87,8.87h62.08c4.85,0,8.87,4.02,8.87,8.87s-4.02,8.87-8.87,8.87h-201.03c-4.85,0-8.87-4.02-8.87-8.87s4.02-8.87,8.87-8.87h62.08c4.85,0,8.87-4.02,8.87-8.87s-4.02-8.87-8.87-8.87h-62.08c-14.66,0-26.61,11.94-26.61,26.61s11.94,26.61,26.61,26.61h56.17v29.56H15.91c-4.85,0-8.87,4.02-8.87,8.87s4.02,8.87,8.87,8.87h425.71c4.85,0,8.87-4.02,8.87-8.87s-4.02-8.87-8.87-8.87Zm-186.25,0h-53.21v-29.56h53.21v29.56Z"
    />
    <path
      d="m370.67,36.85c-24.45,0-44.34,19.9-44.34,44.34s19.89,44.34,44.34,44.34,44.34-19.9,44.34-44.34-19.89-44.34-44.34-44.34Zm8.87,69.32v-13.15c0-4.9-3.97-8.87-8.87-8.87s-8.87,3.97-8.87,8.87v13.15c-10.3-3.67-17.74-13.43-17.74-24.97,0-14.67,11.93-26.61,26.61-26.61s26.61,11.94,26.61,26.61c0,11.54-7.44,21.3-17.74,24.97Z"
    />
    <path
      d="m264.24,36.85c-24.45,0-44.34,19.9-44.34,44.34s19.89,44.34,44.34,44.34,44.34-19.9,44.34-44.34-19.89-44.34-44.34-44.34Zm0,70.95c-4.09,0-7.93-1.01-11.4-2.67l5.84-5.84c3.46-3.46,3.46-9.08,0-12.54s-9.08-3.46-12.54,0l-5.84,5.84c-1.66-3.47-2.67-7.3-2.67-11.4,0-14.67,11.93-26.61,26.61-26.61s26.61,11.94,26.61,26.61-11.93,26.61-26.61,26.61Z"
    />
    <circle cx="228.76" cy="353.17" r="8.87" />
  </svg>
</template>
<script setup></script>
