<template>
  <svg viewBox="0 0 485.23 495.53">
    <path
      d="m184.25,187.52h72.96c1.71,0,2.57-1.69,3.43-3.39v-41.58c0-1.7-1.72-3.39-3.43-3.39h-72.96c-1.71,0-3.44,1.69-3.44,3.39v41.58c0,1.7,1.72,3.39,3.44,3.39Zm2.58-41.58h66.94v34.79h-66.94v-34.79Z"
    />
    <path
      d="m159.36,111.15h225.74c1.72,0,3.43-1.69,3.43-3.39v-28c0-1.7-1.71-3.4-3.43-3.4h-225.74c-1.71,0-3.44,1.7-3.44,3.4v28c0,1.7,1.72,3.39,3.44,3.39Zm3.43-28h218.88v21.21h-218.88v-21.21Z"
    />
    <path
      d="m103.36,457.13c.34.13.7.22,1.06.22h316.73c1.71,0,3.43-1.7,3.43-3.4V27.15c0-1.7-1.72-3.39-3.43-3.39H133.61c-1.72,0-3.43,1.69-3.43,3.39v91.64h-30.05v-22.91c0-1.7-1.72-3.39-3.43-3.39s-3.43,1.69-3.43,3.39v22.91h-12.88c-1.71,0-3.44,1.7-3.44,3.4v305.46c0,15.69,11.75,27.75,26.41,29.48ZM137.04,30.55h280.67v420.01H126.25c2.84-2.34,5.22-5.19,7.01-8.39,0-.02.02-.03.03-.05.21-.38.41-.76.61-1.15.07-.13.14-.27.2-.4.13-.27.25-.55.37-.82.43-.97.81-1.96,1.14-2.99.05-.15.1-.29.14-.44.09-.3.17-.6.25-.91.06-.21.11-.41.16-.62.07-.28.13-.56.19-.84.05-.24.1-.49.15-.73.05-.26.1-.52.14-.78.05-.28.09-.57.12-.86.03-.23.06-.47.09-.7.04-.34.06-.68.09-1.02.01-.19.03-.38.04-.57.03-.54.05-1.08.05-1.63V30.55Zm-53.22,95.03h9.45v84.85c0,1.69,1.71,3.39,3.43,3.39s2.58-.85,3.43-2.54v-85.7h30.05v302.07c0,12.73-10.3,22.91-23.18,22.91s-23.18-10.18-23.18-22.91V125.58Z"
    />
    <path
      d="m180.81,311.4c0,1.7,1.72,3.39,3.44,3.39h72.96c1.71,0,2.57-1.69,3.43-3.39v-94.18c0-1.69-1.72-3.39-3.43-3.39h-72.96c-1.71,0-3.44,1.7-3.44,3.39v94.18Zm6.02-91.64h66.94v89.09h-66.94v-89.09Z"
    />
    <path
      d="m285.53,152.73c0,1.7,1.71,3.4,3.43,3.4h102.14c1.72,0,2.58-1.7,3.44-3.4,0-1.69-1.72-3.39-3.44-3.39h-102.14c-1.72,0-3.43,1.7-3.43,3.39Z"
    />
    <path
      d="m391.11,168.01h-102.14c-1.72,0-3.43,1.69-3.43,3.39s1.71,3.39,3.43,3.39h102.14c1.72,0,2.58-1.69,3.44-3.39,0-1.7-1.72-3.39-3.44-3.39Z"
    />
    <path
      d="m391.11,185.83h-102.14c-1.72,0-3.43,1.69-3.43,3.39s1.71,3.39,3.43,3.39h102.14c1.72,0,2.58-1.7,3.44-3.39,0-1.7-1.72-3.39-3.44-3.39Z"
    />
    <path
      d="m391.11,203.64h-102.14c-1.72,0-3.43,1.7-3.43,3.4s1.71,3.39,3.43,3.39h102.14c1.72,0,2.58-.85,3.44-3.39,0-1.7-1.72-3.4-3.44-3.4Z"
    />
    <path
      d="m391.11,222.31h-102.14c-1.72,0-3.43,1.7-3.43,3.39s1.71,3.39,3.43,3.39h102.14c1.72,0,2.58-1.69,3.44-3.39,0-1.69-1.72-3.39-3.44-3.39Z"
    />
    <path
      d="m391.11,240.13h-102.14c-1.72,0-3.43,1.69-3.43,3.39s1.71,3.39,3.43,3.39h102.14c1.72,0,2.58-.85,3.44-3.39,0-1.7-1.72-3.39-3.44-3.39Z"
    />
    <path
      d="m391.11,258.79h-102.14c-1.72,0-3.43,1.7-3.43,3.4s1.71,3.39,3.43,3.39h102.14c1.72,0,3.44-1.7,3.44-3.39s-1.72-3.4-3.44-3.4Z"
    />
    <path
      d="m184.25,339.4h76.39c1.71,0,3.43-1.69,3.43-3.39s-1.72-3.39-3.43-3.39h-76.39c-1.71,0-3.44,1.7-3.44,3.39s1.72,3.39,3.44,3.39Z"
    />
    <path
      d="m381.67,350.43h-198.28c-1.72,0-3.43,1.69-3.43,3.39s1.71,3.39,3.43,3.39h198.28c2.58,0,3.43-1.69,3.43-3.39s-1.71-3.39-3.43-3.39Z"
    />
    <path
      d="m356.78,374.19h-173.39c-1.72,0-3.43,1.7-3.43,3.39s1.71,3.4,3.43,3.4h173.39c1.72,0,3.43-1.7,3.43-3.4s-1.71-3.39-3.43-3.39Z"
    />
    <path
      d="m326.73,392.01h-143.34c-1.72,0-3.43,1.7-3.43,3.39s1.71,3.39,3.43,3.39h144.2c1.71,0,2.57-.85,2.57-3.39,0-1.69-1.71-3.39-3.43-3.39Z"
    />
    <path
      d="m84.68,474.32c-1.72,0-3.44,1.69-3.44,3.39s1.72,3.39,3.44,3.39h237.76c1.71,0,3.44-1.7,3.44-3.39s-1.72-3.39-3.44-3.39H84.68Z"
    />
    <path
      d="m388.53,488.74h-164.79c-1.72,0-3.44,1.7-3.44,3.39s1.72,3.4,3.44,3.4h164.79c1.72,0,3.44-1.7,3.44-3.4s-1.71-3.39-3.44-3.39Z"
    />
    <path
      d="m425.44,488.74h-20.6c-1.72,0-3.44,1.7-3.44,3.39s1.72,3.4,3.44,3.4h20.6c2.57,0,3.43-1.7,3.43-3.4s-1.71-3.39-3.43-3.39Z"
    />
    <path
      d="m137.9,487.89H59.79c-1.72,0-3.44,1.7-3.44,3.4s1.72,3.39,3.44,3.39h78.11c1.71,0,3.44-1.7,3.44-3.39s-1.72-3.4-3.44-3.4Z"
    />
    <path
      d="m340.47,474.32c-1.72,0-3.44,1.69-3.44,3.39s1.71,3.39,3.44,3.39h20.6c1.72,0,3.43-1.7,3.43-3.39s-1.71-3.39-3.43-3.39h-20.6Z"
    />
    <path
      d="m113.87,58.55c2.57,0,3.43-.85,2.57-3.4V6.79h66.1c1.71,0,3.43-1.7,3.43-3.39s-1.72-3.4-3.43-3.4h-68.67c-1.71,0-3.44,1.7-3.44,3.4v51.76c0,1.7,1.72,3.4,3.44,3.4Z"
    />
  </svg>
</template>
<script setup></script>
