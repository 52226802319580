<template>
  <div class="mobile-category-container" id="bazar">
    <div class="header bg">
      <div>
        <span>بازار</span>
        <span class="bold">اکسپلیمر</span>
      </div>
      <div class="text">
        برای مشاهده محتویات هر یک از موارد زیر، آن را انتخاب کنید.
      </div>
    </div>
    <div class="parts">
      <div class="arrow-right" @click="navigate(0)"><ArrowRightSvg /></div>
      <div class="part">
        <div class="image" v-if="active === 0"><SupportMaterialSvg /></div>
        <div class="image" v-if="active === 1"><MasterPech /></div>
        <div class="image" v-if="active === 2"><PolymerAdditionalSvg /></div>
        <div class="image" v-if="active === 3"><FactorySvg /></div>
        <div class="header">{{ items[active].header }}</div>
        <div class="text">
          {{ items[active].text }}
        </div>
        <span class="button" @click="goLocation(items[active].header)"
          >اطلاعات بیشتر</span
        >
      </div>

      <div class="arrow-left" @click="navigate(1)"><ArrowRightSvg /></div>
    </div>
    <div class="dots">
      <div :class="active === 0 && 'active'" @click="setActive(0)"></div>
      <div :class="active === 1 && 'active'" @click="setActive(1)"></div>
      <div :class="active === 2 && 'active'" @click="setActive(2)"></div>
      <div :class="active === 3 && 'active'" @click="setActive(3)"></div>
    </div>
  </div>
  <div class="category-container" id="bazar">
    <div class="header bg">
      <div>
        <span class="bold">بازار</span>
        <span>اکسپلیمر</span>
      </div>
      <div class="text">
        برای مشاهده محتویات هر یک از موارد زیر، آن را انتخاب کنید.
      </div>
    </div>
    <div class="parts">
      <div class="part">
        <div class="image"><SupportMaterialSvg /></div>
        <div class="header">مشاوره مواد</div>
        <div class="text">
          پلی‌پروپیلن یک پلیمر حرارتی است که اولین بار در دهه ۱۹۵۰ توسط تیتو
          سپیک اختراع شد. این مواد پلیمری به سرعت با موادی مانند پلاستیک های سفت
          و فلزات در صنایع مختلف جایگزین شدند.
        </div>
        <span
          class="button"
          @click="
            goLocation('https://expolymer.ir/blog/materials-consultation/')
          "
          >اطلاعات بیشتر</span
        >
      </div>
      <div class="part">
        <div class="image"><MasterPech /></div>
        <div class="header">مستربچ</div>
        <div class="text">
          پلی‌پروپیلن یک پلیمر حرارتی است که اولین بار در دهه ۱۹۵۰ توسط تیتو
          سپیک اختراع شد. این مواد پلیمری به سرعت با موادی مانند پلاستیک های سفت
          و فلزات در صنایع مختلف جایگزین شدند.
        </div>
        <span
          class="button"
          @click="goLocation('https://expolymer.ir/blog/masterbach/')"
          >اطلاعات بیشتر</span
        >
      </div>
      <div class="part">
        <div class="image"><PolymerAdditionalSvg /></div>
        <div class="header">افزودنی های پلیمری</div>
        <div class="text">
          پلی‌پروپیلن یک پلیمر حرارتی است که اولین بار در دهه ۱۹۵۰ توسط تیتو
          سپیک اختراع شد. این مواد پلیمری به سرعت با موادی مانند پلاستیک های سفت
          و فلزات در صنایع مختلف جایگزین شدند.
        </div>
        <span
          class="button"
          @click="goLocation('https://expolymer.ir/blog/polymer-additives/')"
          >اطلاعات بیشتر</span
        >
      </div>
      <div class="part">
        <div class="image"><FactorySvg /></div>
        <div class="header">دستگاه های صنعتی</div>
        <div class="text">
          پلی‌پروپیلن یک پلیمر حرارتی است که اولین بار در دهه ۱۹۵۰ توسط تیتو
          سپیک اختراع شد. این مواد پلیمری به سرعت با موادی مانند پلاستیک های سفت
          و فلزات در صنایع مختلف جایگزین شدند.
        </div>
        <span
          class="button"
          @click="goLocation('https://expolymer.ir/blog/industrial-devices/')"
          >اطلاعات بیشتر</span
        >
      </div>
    </div>
  </div>
</template>
<script setup>
import PolymerAdditionalSvg from "./svgs/PolymerAdditionalSvg.vue";
import SupportMaterialSvg from "./svgs/SupportMaterialSvg.vue";
import FactorySvg from "./svgs/FactorySvg.vue";
import ArrowRightSvg from "./svgs/ArrowRightSvg.vue";
import MasterPech from "./svgs/MasterPech.vue";
import { ref } from "vue";
const goLocation = (e) => {
  // window.location = e;
  window.open(e, "_blank");
};
const items = [
  {
    header: "مشاوره مواد",
    link: "https://expolymer.ir/blog/materials-consultation/",
    text: ` پلی‌پروپیلن یک پلیمر حرارتی است که اولین بار در دهه ۱۹۵۰ توسط تیتو
          سپیک اختراع شد. این مواد پلیمری به سرعت با موادی مانند پلاستیک های سفت
          و فلزات در صنایع مختلف جایگزین شدند.`,
  },
  {
    header: "مستربچ",
    link: "https://expolymer.ir/blog/masterbach/",
    text: ` پلی‌پروپیلن یک پلیمر حرارتی است که اولین بار در دهه ۱۹۵۰ توسط تیتو
          سپیک اختراع شد. این مواد پلیمری به سرعت با موادی مانند پلاستیک های سفت
          و فلزات در صنایع مختلف جایگزین شدند.`,
  },
  {
    header: "افزودنی های پلیمری",
    link: "https://expolymer.ir/blog/polymer-additives/",
    text: ` پلی‌پروپیلن یک پلیمر حرارتی است که اولین بار در دهه ۱۹۵۰ توسط تیتو
          سپیک اختراع شد. این مواد پلیمری به سرعت با موادی مانند پلاستیک های سفت
          و فلزات در صنایع مختلف جایگزین شدند.`,
  },
  {
    header: "دستگاه های صنعتی",
    link: "https://expolymer.ir/blog/industrial-devices/",
    text: ` پلی‌پروپیلن یک پلیمر حرارتی است که اولین بار در دهه ۱۹۵۰ توسط تیتو
          سپیک اختراع شد. این مواد پلیمری به سرعت با موادی مانند پلاستیک های سفت
          و فلزات در صنایع مختلف جایگزین شدند.`,
  },
];
const active = ref(0);
const setActive = (e) => {
  active.value = e;
};
const navigate = (e) => {
  if (e === 1 && active.value !== 0) {
    active.value = active.value - 1;
  }
  if (e === 0 && active.value !== 3) {
    active.value = active.value + 1;
  }
};
</script>
<style lang="scss" scoped>
.mobile-category-container {
  display: none;
}
.category-container {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  width: 98.5dvw;
  z-index: 10;
  .bg {
    background-color: white;
    padding-bottom: 24px;
  }
  .header {
    text-align: center;
    font-size: 28px;
    font-weight: bold;

    .bold {
      color: #1c66e1;
      padding-left: 5px;
    }
    .text {
      font-size: 14px;
      font-weight: normal;
      color: #b4b4b4;
      margin-top: 10px;
    }
  }
  .parts {
    /* margin-top: 24px; */
    display: flex;
    justify-content: center;
    align-items: center;

    height: 475px;
    background-color: #1c66e1;
    padding-top: 20px;

    .part {
      height: 100%;
      width: 300px;
      border-left: 1px solid rgba(255, 255, 255, 0.552);
      .image {
        background-color: #0f2851;
        border-radius: 15px;
        width: 80px;
        height: 85px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 60px 20px 0px 0px;
        svg {
          width: 60px;
          height: 60px;
          fill: white;
        }
      }
      .header {
        color: white;
        text-align: right;
        font-size: 20px;
        margin: 60px 20px 0px 0px;
      }
      .text {
        font-size: 14px;
        color: white;
        margin: 20px 20px 0px 20px;
      }
      .button {
        margin: 15px 0px 0px 0px;
        color: white;
        display: flex;
        font-weight: bold;
        padding: 7px 0px;
        padding-right: 20px;
        width: 50%;
        cursor: pointer;
      }
      &:last-child {
        border-left: none;
      }
      &:hover {
        background-color: #0f2851;
        background-image: url("../assets/hex.webp");
        background-position: left;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 24px;
        transform: translateY(-10%);
        border-left: none;
        transition: 0.3s;
        .image {
          background-color: #1c66e1;
        }
        .button {
          background-color: #1c66e1;
          padding-right: 25px;
        }
      }
    }
  }
}
@media only screen and (max-width: 1050px) {
  .category-container {
    .header {
      font-size: 26px;
      .text {
        font-size: 13px;
      }
    }
    .parts {
      width: 100vw;
      height: 475px;
      .part {
        width: 290px;

        .image {
          width: 75px;
          height: 80px;

          svg {
            width: 55px;
            height: 55px;
          }
        }
        .header {
          font-size: 18px;
        }
        .text {
          font-size: 13px;
        }
        .button {
          font-size: 15px;
        }
      }
    }
  }
}
@media only screen and (max-width: 770px) {
  .category-container {
    .header {
      font-size: 24px;
      .text {
        font-size: 11px;
      }
    }
    .parts {
      width: 100vw;
      height: 475px;
      .part {
        width: 280px;

        .image {
          width: 65px;
          height: 70px;
          margin: 35px 20px 0px 0px;
          svg {
            width: 45px;
            height: 45px;
          }
        }
        .header {
          font-size: 16px;
        }
        .text {
          font-size: 11px;
        }
        .button {
          font-size: 13px;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .category-container {
    display: none;
  }
  .mobile-category-container {
    margin-top: 3rem;
    background-color: #1c66e1;
    padding-top: 1rem;
    .header {
      color: white;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      .bold {
        color: #0f2851;
        padding-right: 5px;
      }
      .text {
        font-size: 12px;
        font-weight: normal;
        color: white;
        margin-top: 10px;
      }
    }
    width: 100vw;
    display: inline;
    overflow: hidden;
    position: relative;
    .parts {
      overflow: hidden;
      display: flex;

      /* justify-content: center; */
      align-items: center;
      margin: 20px;
      margin-top: 40px;
      background-color: #0f2851;
      border-radius: 18px;
      padding: 15px;
      padding-right: 0;
      .arrow-right {
        position: absolute;
        right: 9px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        width: 20px;
        height: 20px;
        border-radius: 3px;
        border: 1px solid #1c66e1;
        svg {
          width: 12px;
          height: 12px;
        }
      }
      .arrow-left {
        position: absolute;
        left: 9px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        width: 20px;
        height: 20px;
        border-radius: 3px;
        border: 1px solid #1c66e1;
        svg {
          transform: rotate(180deg);
          width: 12px;
          height: 12px;
        }
      }
      .part {
        min-width: 100vw;
        color: white;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-image: url("../assets/hexagons.webp");
        background-position: left;
        background-repeat: no-repeat;
        background-size: cover;
        .image {
          display: inline;
          background-color: #1c66e1;
          border-radius: 10px;
          margin-right: 25px;
          padding: 10px;
          svg {
            width: 60px;
            height: 60px;
            fill: white;
          }
        }
        .header {
          margin-top: 25px;
          margin-bottom: 10px;
          margin-right: 25px;
          font-size: 20px;
        }
        .text {
          width: 80%;
          font-size: 11px;
          margin-bottom: 20px;
          margin-right: 25px;
        }
        .button {
          background-color: #1c66e1;
          font-size: 13px;
          padding: 7px;
          padding-right: 25px;
          padding-left: 15px;
        }
      }
    }
    .dots {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row-reverse;
      gap: 10px;
      margin-bottom: 2rem;
      div {
        width: 6px;
        height: 6px;
        background-color: white;
        border-radius: 2px;
        background-color: #538ce8;
        &.active {
          background-color: #0f2851;
        }
      }
    }
  }
}
</style>
