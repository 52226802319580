import { createRouter, createWebHistory } from "vue-router";

import OverviewPage from "../pages/OverviewPage.vue";
import AdminPage from "../pages/AdminPage.vue";
import LoginPage from "../pages/LoginPage.vue";
const routes = [
  {
    path: "/",
    name: "overviewPage",
    component: OverviewPage,
  },
  {
    path: "/login",
    name: "loginPage",
    component: LoginPage,
  },
  {
    path: "/admin",
    name: "adminPage",
    component: AdminPage,
    meta: { requiresAuth: true },
  },
  { path: "/:pathMatch(.*)*", redirect: "/login" },
];

// history: createWebHashHistory(),
const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
// ,{
//   path: "/admin",
//   component: Sidebar,
//   redirect: "/admin/overview",
// children: [
//   {
//     path: "baseInfo",
//     name: "BaseInfo",
//     component: BaseInfoPage,
//     meta: { requiresAuth: true },
//   }
// ],
// },
// { path: "/:pathMatch(.*)*", redirect: "/admin/overview" },s
