<template>
  <svg viewBox="0 0 457.53 446.19">
    <path
      d="m151.51,277.22h154.96c4.86-36.26,4.9-72.18,0-108.14h-155.18c-5.15,25.57-5.01,90.38.22,108.14Z"
    />
    <path
      d="m301.7,141.48c-.62-2.74-1.14-5.21-1.74-7.66-6.34-25.6-14.96-50.27-30.11-72.19-6.64-9.61-14.36-18.18-24.74-23.92-10.38-5.74-20.91-6.08-31.55-.57-8.05,4.17-14.61,10.13-20.23,17.1-20.54,25.51-30.17,55.72-37.62,87.24h145.99Z"
    />
    <path
      d="m301.87,304.69h-146.14c7.06,30.39,16.3,59.59,35.5,84.5,4.43,5.75,9.75,11.07,15.51,15.48,14.92,11.43,30.05,11.52,44.51-.44,7.5-6.21,14.17-13.81,19.82-21.77,12.29-17.3,19.63-37.04,25.47-57.31,1.93-6.7,3.52-13.49,5.32-20.45Z"
    />
    <path
      d="m425.85,172.67c-.79-3.14-2.07-3.91-5.11-3.89-27.53.11-55.05.07-82.58.07h-4.27c4.48,36.35,4.48,72.11.01,108.25.98.12,1.52.26,2.07.26,28.66.01,57.31-.02,85.97.08,2.69,0,3.18-1.29,3.73-3.42,8.71-33.77,8.68-67.52.19-101.33Z"
    />
    <path
      d="m119.36,277.37h4.31c-4.5-36.32-4.5-72.08-.04-108.23-.94-.13-1.48-.27-2.03-.27-28.66-.01-57.32.02-85.97-.08-2.67,0-3.19,1.25-3.75,3.4-8.73,33.77-8.68,67.52-.2,101.33.78,3.11,2.03,3.93,5.1,3.92,27.53-.12,55.06-.07,82.58-.07Z"
    />
    <path
      d="m414.39,141.56c-10.86-45.85-89.58-111.25-139.87-116.12,32.43,32.18,45.68,73.36,55.24,116.12h84.63Z"
    />
    <path
      d="m146.8,79.69c8.8-20.04,19.82-38.66,36.62-54.27-50.5,4.69-127.27,68.42-140.34,116.08h84.85c4.31-21.22,10.16-41.97,18.87-61.81Z"
    />
    <path
      d="m310.6,366.81c-8.8,20.02-19.89,38.56-35.85,53.97,34.2-7.78,64.22-23.45,90.18-46.86,21.52-19.41,38.13-42.37,50.02-69.28h-85.34c-4.34,21.36-10.25,42.22-19.02,62.17Z"
    />
    <path
      d="m42.6,304.65c13.24,29.78,32.14,54.55,56.69,75.06,23,19.22,69.94,42.06,83.33,40.63-32.06-31.92-45.29-72.89-54.84-115.7H42.6Z"
    />
  </svg>
</template>
<script setup></script>
