<template>
  <svg viewBox="0 0 457.53 446.19">
    <path
      d="m329.84,443.59H127.72c-24.52,0-47.89-19.65-52.1-43.82L8.43,13.35c-.87-4.99,2.48-9.74,7.47-10.61,4.99-.87,9.74,2.48,10.61,7.47l67.2,386.42c2.69,15.5,18.26,28.58,33.99,28.58h202.12c15.73,0,31.3-13.1,33.99-28.58L431.02,10.22c.87-4.99,5.62-8.34,10.61-7.47,4.99.87,8.34,5.62,7.47,10.61h0l-67.18,386.41c-4.19,24.17-27.56,43.82-52.08,43.82Z"
    />
    <path
      d="m421.71,122.03c-26.74-.02-51.36-14.55-64.31-37.94-12.55,22.61-36.68,37.94-64.31,37.94s-51.76-15.33-64.31-37.94c-12.55,22.61-36.68,37.94-64.31,37.94s-51.76-15.33-64.31-37.94c-12.95,23.4-37.57,37.93-64.31,37.94-5.07,0-9.19-4.11-9.19-9.19s4.11-9.19,9.19-9.19c30.4,0,55.12-24.72,55.12-55.12,0-5.07,4.11-9.19,9.19-9.19s9.19,4.11,9.19,9.19c0,30.4,24.72,55.12,55.12,55.12s55.12-24.72,55.12-55.12c0-5.07,4.11-9.19,9.19-9.19s9.19,4.11,9.19,9.19c0,30.4,24.72,55.12,55.12,55.12s55.12-24.72,55.12-55.12c0-5.07,4.11-9.19,9.19-9.19s9.19,4.11,9.19,9.19c0,30.4,24.72,55.12,55.12,55.12,5.07,0,9.19,4.11,9.19,9.19s-4.11,9.19-9.19,9.19Z"
    />
    <circle cx="283.9" cy="269.03" r="18.37" />
    <circle cx="228.78" cy="269.03" r="18.37" />
    <circle cx="173.66" cy="269.03" r="18.37" />
  </svg>
</template>
<script setup></script>
