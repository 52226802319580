<template>
  <svg viewBox="0 0 443.88 438.53">
    <path
      d="m373.2,388.07c-.4,10.08-4.35,22.5-12.12,33.59-11.02,15.72-27.17,20.54-45.23,14.08-29.12-10.41-58.29-20.73-87.5-30.88-18.63-6.47-27.92-20-26.57-40.06.9-13.38,5.46-25.55,13.74-36.16,10.48-13.45,25.76-17.89,41.88-12.23,30.43,10.68,60.84,21.42,91.24,32.17,15.82,5.59,25.14,19.25,24.57,39.49Zm-59.63-35.16c-.73-.35-1.31-.7-1.93-.92-19.41-6.87-38.84-13.69-58.23-20.6-10.83-3.86-19.42-1.41-26.33,7.78-5.17,6.88-8.37,14.68-9.54,23.12-1.95,14.15,2,23.34,16.99,28.3,18.58,6.15,36.95,12.96,55.41,19.47.98.35,2.01.59,3.13.92-2.76-22.96,3.87-42.18,20.51-58.08Zm-5.84,45.36c-.1,12.49,4.93,19.92,14.54,23.24,10.14,3.51,18.65,1,25.28-7.76,6.42-8.49,9.94-18.16,10.16-28.79.07-3.64-.62-7.6-2.09-10.92-6.43-14.59-26.77-16.67-37.08-3.86-6.95,8.65-10.17,18.8-10.82,28.09Z"
    ></path>
    <path
      d="m410.34,214.24c-.6,5.97-1.26,12.83-3.48,18.71-5.1,13.46-15.86,19.95-29.66,22.13-18.24,2.88-36.47,5.86-54.71,8.79-13.53,2.17-27.02,4.59-40.59,6.41-15.08,2.01-27.45-3.29-35.47-16.28-9.76-15.82-12.67-33.08-7.24-51.12,4.17-13.86,14.12-21.9,28.15-24.27,31.72-5.36,63.5-10.32,95.26-15.41,20.22-3.24,35.94,6.1,43.06,25.42,3.05,8.28,4.58,16.82,4.68,25.63.28.06-.28-.06,0,0Zm-65.22,30.15c-13.55-18.85-16.7-38.92-9.89-60.77-.67,0-1.25-.07-1.8.01-4.31.66-8.62,1.33-12.93,2.02-16.63,2.67-33.27,5.23-49.87,8.06-9.91,1.69-16.06,8.16-17.73,17.95-1.71,10-.26,19.64,3.73,28.87,4.46,10.29,12.34,16.62,26.05,14.14,18.17-3.28,36.45-5.91,54.68-8.85,2.55-.41,5.08-.94,7.76-1.44Zm49.68-32.75c-.27-7.73-2.14-16.81-7.63-24.75-5.07-7.33-12.24-9.93-20.96-8.59-8.19,1.26-14.47,5.38-16.95,13.38-4.48,14.44-2.28,28.3,6.56,40.49,8.62,11.88,28.47,9.96,35.59-2.9,2.75-4.96,3.43-10.33,3.39-17.63Z"
    ></path>
    <path
      d="m258.09,155.78c-15.92-1.56-31.27-8.74-41.92-23.92-7.7-10.98-7.46-22.91-1.36-34.63,13.17-25.29,26.5-50.48,39.74-75.73,9.02-17.19,23.18-23.63,42.14-18.73,13.03,3.37,24.27,9.87,32.35,20.9,8.13,11.1,9.04,23.19,2.8,35.33-13.6,26.42-27.47,52.71-41.44,78.94-6.32,11.86-16.49,17.81-32.31,17.84Zm-2.46-102.29c-.42.46-.68.64-.81.9-8.85,16.88-17.79,33.71-26.5,50.66-4.02,7.84-2.73,15.09,3.33,21.55,5.17,5.51,11.47,9.29,18.59,11.72,14.25,4.87,22.7.65,28.59-11.35,7.23-14.74,15.16-29.13,22.78-43.68.73-1.4,1.43-2.82,2.29-4.53-20.2-.91-36.17-9.18-48.26-25.27Zm43.39,9.52c9.52.13,15.06-3.5,18.67-10.28,3.66-6.89,3.87-13.74-1.15-19.97-7.99-9.91-18.51-15.44-31.25-15.93-7.38-.28-12.6,3.76-16.05,10.19-3.38,6.31-3.95,12.72.3,18.71,7.58,10.69,18.52,15.63,29.48,17.29Z"
    ></path>
    <path
      d="m222.21,39.03c.05-5.02-.86-9.95-2.49-14.77-.4-1.34-.92-2.65-1.49-3.94-.27-.63-.54-1.26-.83-1.88C211.75,6.17,202.37-.12,190.85,0c-.06,0-.12,0-.19,0-.03,0-.07,0-.11.01-2.88.06-5.9.51-9.02,1.38-19.93,5.54-39.91,10.93-59.92,16.18-2.43.64-4.67,1.42-6.74,2.35-5.46,2.12-9.68,5.63-12.53,10.42-2.92,4.6-4.38,10.21-4.27,16.78-.05,5.02.86,9.95,2.49,14.77.4,1.34.92,2.65,1.49,3.94.27.63.54,1.26.83,1.88,5.65,12.27,15.02,18.56,26.54,18.43.06,0,.12,0,.19,0,.03,0,.07,0,.11-.01,2.88-.06,5.9-.51,9.02-1.38,19.93-5.54,39.91-10.93,59.92-16.18,2.43-.64,4.67-1.42,6.74-2.35,5.46-2.12,9.68-5.63,12.53-10.42,2.92-4.6,4.38-10.21,4.27-16.78Zm-25.72,13.85c-17.21,4.73-34.44,9.33-51.67,13.98-3.92,1.06-7.83,2.13-11.75,3.16-5.83,1.53-10.06.09-13.59-4.79-3.93-5.43-5.59-11.63-5.8-16.81-.03-5.57,1-9.23,3.45-11.72,1.61-1.52,3.8-2.62,6.68-3.42,17.21-4.73,34.44-9.33,51.67-13.98,3.92-1.06,7.83-2.13,11.75-3.16,5.83-1.53,10.06-.09,13.59,4.79,3.93,5.43,5.59,11.63,5.8,16.81.03,5.57-1,9.23-3.45,11.72-1.61,1.52-3.8,2.62-6.68,3.41Z"
    ></path>
    <path
      d="m42.43,212.97c7.13.1,13.85,1.84,20.14,5.17,12.36,6.55,17.16,17.53,13.67,31.14-4.78,18.66-9.47,37.35-14.36,55.99-2.8,10.67-9.8,17.27-20.72,18.85-12.12,1.75-23.26-1.25-32.64-9.27-7.12-6.08-9.94-14.13-7.84-23.29,2.99-13.04,6.47-25.98,9.77-38.95,1.62-6.39,3.26-12.77,4.93-19.14,3.59-13.7,13.02-20.82,27.06-20.49Zm-7.57,96.18c7.4-.01,10.72-2.52,12.36-8.93,4.78-18.66,9.53-37.32,14.25-55.99,1.26-4.99,0-8.39-4.33-11.26-5.24-3.48-11.09-4.95-17.35-4.44-4.99.41-7.77,2.69-9.04,7.62-4.94,19.04-9.81,38.09-14.61,57.16-1.34,5.34.11,8.61,4.78,11.61,4.48,2.87,9.49,3.97,13.95,4.24Z"
    ></path>
    <path
      d="m437.3,272.45c3.75,6.07,5.89,12.68,6.45,19.77,1.12,13.94-5.57,23.89-18.92,28.26-18.31,5.99-36.59,12.07-54.94,17.96-10.51,3.37-19.83,1.02-27.03-7.35-7.99-9.28-11.44-20.29-9.71-32.51,1.31-9.27,6.58-15.97,15.44-19.12,12.61-4.48,25.39-8.49,38.1-12.69,6.26-2.06,12.52-4.11,18.8-6.13,13.48-4.33,24.56-.21,31.82,11.81Zm-85.2,45.28c3.99,6.23,7.88,7.69,14.17,5.63,18.3-5.99,36.6-12.01,54.88-18.06,4.89-1.61,7.07-4.51,7.17-9.71.12-6.29-1.78-12.01-5.57-17.02-3.02-3.99-6.44-5.1-11.28-3.53-18.71,6.06-37.4,12.19-56.06,18.38-5.22,1.73-7.21,4.72-7.22,10.27-.01,5.32,1.75,10.14,3.91,14.04Z"
    ></path>
    <path
      d="m425.52,126.24c-2.16,5.63-5.51,10.46-9.99,14.48-8.81,7.91-18.93,8.51-28.72,1.77-13.43-9.24-26.9-18.42-40.27-27.75-7.66-5.34-10.86-12.82-8.92-21.96,2.15-10.14,7.78-18.1,16.89-23.21,6.91-3.88,14.12-3.77,20.79.57,9.48,6.18,18.74,12.72,28.08,19.12,4.6,3.15,9.19,6.31,13.76,9.5,9.83,6.85,12.74,16.42,8.38,27.47Zm-74.17-34.09c-2.15,5.88-1.13,9.24,3.48,12.42,13.42,9.24,26.86,18.46,40.3,27.66,3.59,2.46,6.66,2.44,10.21-.16,4.29-3.14,7.17-7.36,8.59-12.48,1.13-4.08.13-6.95-3.41-9.4-13.67-9.48-27.38-18.91-41.12-28.29-3.84-2.62-6.87-2.43-10.61.41-3.59,2.72-5.92,6.38-7.44,9.84Z"
    ></path>
    <path
      d="m202.52,150.51c-1.73-21.5-13.47-34.74-34.4-39.33-12.58-2.76-25.26-5.1-37.89-7.63-23.68-4.74-47.39-9.3-71.02-14.27-18.64-3.91-34.24,1.47-44.59,16.08-6.85,9.67-10.26,20.62-11.86,32.23-1.34,9.79-.98,19.45,2.42,28.83,5.67,15.62,17.49,23.55,33.3,26.35l6.09,1.21c7.04,1.51,14.11,2.91,21.17,4.33,27.06,5.42,54.14,10.72,81.17,16.28,19.15,3.94,35.77-3.02,45.7-19.91,8.01-13.61,11.15-28.54,9.89-44.17Zm-115.46,36.22c-16.3-3.29-32.64-6.45-48.9-9.95-11.24-2.41-18.19-9.85-19.9-21.14-2.11-13.94.5-27.12,7.68-39.22,6.32-10.65,15.79-14.75,27.95-12.34,22.49,4.46,44.94,9.07,67.41,13.6,3.64.73,7.29,1.39,11.52,2.2-9.88,9.97-15.16,21.59-17.71,34.55-2.53,12.88-2.39,25.59,3.13,38.54-10.94-2.19-21.07-4.21-31.19-6.25Zm90.36,2.66c-6.68,9.31-16.11,12.61-27.31,9.99-11.25-2.63-18.35-9.52-20.25-20.89-2.45-14.67.37-28.53,8.42-41.08,6.25-9.74,16.03-13.42,27.48-10.93,11.63,2.53,18.73,9.73,20.7,21.12.5,2.89.75,5.82,1,7.77-.64,12.83-2.98,24.17-10.04,34.01Z"
    ></path>
    <path
      d="m115.73,428.85c21.52,1.43,36.33-8.26,43.93-28.29,4.57-12.04,8.73-24.24,13.08-36.37,8.14-22.73,16.12-45.52,24.48-68.18,6.59-17.87,3.54-34.09-9.4-46.46-8.56-8.19-18.9-13.16-30.16-16.43-9.49-2.76-19.1-3.81-28.87-1.81-16.28,3.33-25.85,13.86-30.93,29.1l-2.08,5.85c-2.52,6.75-4.94,13.53-7.37,20.31-9.31,25.98-18.51,52-27.95,77.92-6.69,18.37-2.23,35.83,13.03,48.12,12.3,9.91,26.61,15.19,42.26,16.23Zm-18.98-119.51c5.63-15.65,11.15-31.35,16.97-46.92,4.03-10.77,12.4-16.56,23.82-16.6,14.1-.05,26.76,4.45,37.68,13.32,9.62,7.81,12.28,17.77,8.13,29.45-7.69,21.6-15.53,43.14-23.29,64.71-1.26,3.49-2.44,7.01-3.86,11.08-8.42-11.23-19.14-18.15-31.6-22.56-12.37-4.38-24.97-6.09-38.58-2.53,3.77-10.51,7.24-20.23,10.73-29.94Zm-15.82,89.01c-8.23-7.96-10.12-17.78-5.9-28.48,4.24-10.75,12.09-16.76,23.62-16.99,14.87-.29,28.17,4.53,39.41,14.33,8.72,7.61,10.93,17.81,6.8,28.79-4.2,11.13-12.36,17.11-23.91,17.4-2.93.07-5.87-.11-7.84-.15-12.6-2.5-23.48-6.48-32.18-14.9Z"
    ></path>
  </svg>
</template>
<script setup></script>
