<template>
  <svg viewBox="0 0 593.15 497.6">
    <path
      d="m511.9,127.27c-.19-.34-.37-.68-.56-1.02-.15-.28-.31-.56-.46-.83-.12-.21-.24-.42-.36-.63l-1.11.64,1.67,2.89,1.05-.61h.01c-.08-.15-.16-.29-.24-.44ZM85.76,369.4h-.01s-1.06.6-1.06.6c.27.5.54.99.82,1.48.26.49.53.97.8,1.45h.01s1.1-.64,1.1-.64l-1.66-2.89Z"
    />
    <path
      d="m589.86,106.4c-6.87,12.1-22.26,16.34-34.36,9.47-3.11-1.76-5.69-4.09-7.71-6.78-.33-.44-.65-.89-.94-1.36-.31-.46-.59-.94-.86-1.42-.01-.01-.02-.03-.02-.04-.27-.48-.52-.97-.76-1.46-.24-.5-.46-1-.66-1.52-2.74-6.86-2.45-14.86,1.48-21.79,6.87-12.11,22.26-16.35,34.35-9.48,12.12,6.88,16.35,22.27,9.47,34.37h.01Z"
    />
    <path
      d="m37.5,428.19c-12.15,6.8-27.5,2.46-34.29-9.68-6.8-12.16-2.45-27.51,9.7-34.31,11.07-6.19,24.81-3.14,32.29,6.65.73.95,1.4,1.97,2,3.04.5.89.93,1.79,1.31,2.71,4.81,11.64.26,25.29-11.01,31.59Z"
    />
    <path
      d="m512.14,127.72c-.53-.99-1.06-1.96-1.62-2.93l-1.11.64,1.67,2.89,1.05-.61h0Z"
    />
    <path
      d="m87.43,372.29l-1.67-2.89-1.05.61c.53.99,1.06,1.96,1.62,2.93l1.11-.64h-.01Z"
    />
    <g>
      <path
        d="m550.59,105.57l-1.67-2.89-38.4,22.11c.55.96,1.09,1.94,1.62,2.93l38.46-22.15h-.01Z"
      />
      <path
        d="m549.53,100.43l-4.97,2.87-36.19,20.84-.09.05.82,1.43c.55.95,1.07,1.91,1.6,2.89l.8,1.49.12-.07,36.17-20.84h.01l5.05-2.91-3.32-5.75Zm-36.75,25.02s-.01-.03-.02-.05l33.21-19.13,2.35-1.35.02.04-2.35,1.35-33.21,19.14Z"
      />
    </g>
    <g>
      <path
        d="m86.31,372.93c-.55-.96-1.09-1.94-1.62-2.93l-38.46,22.15,1.66,2.89,38.41-22.11h.01Z"
      />
      <path
        d="m87.74,372.11c-.55-.95-1.07-1.92-1.6-2.89l-.8-1.49-.37.22-2.61,1.5-37.16,21.4-1.21.7,3.31,5.75,1.21-.7,37.22-21.42,2.61-1.5.23-.14-.83-1.43Zm-39.24,20.68l-.02-.04,35.49-20.44.08-.05h.01s.02.04.02.06l-.08.04-35.5,20.43Z"
      />
    </g>
    <path
      d="m465.32,112.4l-135.04-77.97c-20.87-12.05-46.59-12.05-67.46,0L127.76,112.4c-20.88,12.06-33.74,34.32-33.74,58.42v155.95c0,24.1,12.86,46.37,33.74,58.42l135.05,77.97c20.87,12.05,46.59,12.05,67.46,0l135.05-77.97c20.88-12.06,33.74-34.32,33.74-58.42v-155.95c0-24.1-12.86-46.37-33.74-58.42ZM121.25,321.32l-1.91-140.13c-.13-9.06,13.94-9.24,14.07-.19l1.91,140.13c.12,9.05-13.94,9.23-14.07.19Zm63.33,27.48c-4.42,0-8-3.58-8-8s3.58-8,8-8h24v-122.65c-.01-2.68,1.33-5.18,3.56-6.67l80-53.33c1.31-.88,2.86-1.34,4.44-1.35,4.41,0,8,3.58,8,8v48h72c8.82,0,16,7.18,16,16v112h16c4.42,0,8,3.58,8,8s-3.58,8-8,8h-224Zm267.51,16.08h0c-5.03,3.05-10.07,6.12-15.11,9.18-34.88,21.21-69.74,42.44-104.62,63.64-7.76,4.72-15-7.34-7.27-12.04,5.03-3.07,10.08-6.13,15.12-9.19,34.87-21.21,69.74-42.42,104.61-63.65,7.76-4.71,15,7.36,7.27,12.06Zm-147.51-32.08h72v-112h-72v112Zm-32-48c-4.42,0-8,3.58-8,8v16c0,4.42,3.58,8,8,8s8-3.58,8-8v-16c0-4.42-3.58-8-8-8Zm0-56c-4.42,0-8,3.58-8,8v16c0,4.42,3.58,8,8,8s8-3.58,8-8v-16c0-4.42-3.58-8-8-8Zm-32,56c-4.42,0-8,3.58-8,8v16c0,4.42,3.58,8,8,8s8-3.58,8-8v-16c0-4.42-3.58-8-8-8Zm0-56c-4.42,0-8,3.58-8,8v16c0,4.42,3.58,8,8,8s8-3.58,8-8v-16c0-4.42-3.58-8-8-8Z"
    />
    <path
      d="m511.62,129.93c-1.02-1.96-2.1-3.9-3.25-5.79-8.4-13.87-20.23-25.68-34.69-34.03L345.41,16.05c-3.19-1.84-6.5-3.51-9.84-4.96-.28-.12-.56-.24-.85-.35-28.09-11.94-60.57-9.98-87.04,5.31L119.4,90.11c-30.14,17.4-48.86,49.83-48.86,84.63v148.12c0,16.21,4.07,32.29,11.78,46.51.01.03.03.05.04.08.26.48.52.95.8,1.43.26.48.54.95.81,1.43.01,0,.02.03.03.05.28.47.56.94.85,1.4.29.48.58.95.88,1.42.02.04.05.07.07.11,8.4,13.22,20.02,24.36,33.6,32.2l128.27,74.06c3.19,1.84,6.5,3.51,9.84,4.96.28.12.56.24.85.36,12.17,5.17,25.16,7.73,38.13,7.73h.01c16.97,0,33.9-4.38,48.91-13.05l128.27-74.06c30.14-17.4,48.86-49.83,48.86-84.63v-148.12c0-15.82-3.87-31.16-10.92-44.81Zm4.92,192.93c0,32.67-17.57,63.11-45.86,79.44l-128.27,74.06c-14.09,8.13-29.98,12.25-45.91,12.25-12.23,0-24.47-2.43-35.93-7.32-.22-.09-.44-.18-.66-.28-3.13-1.36-6.24-2.92-9.23-4.65l-128.27-74.06c-12.75-7.36-23.65-17.81-31.54-30.23-1.16-1.81-2.25-3.68-3.26-5.55-7.23-13.35-11.06-28.45-11.06-43.66v-148.12c0-32.67,17.57-63.11,45.86-79.44L250.68,21.25c14.09-8.13,29.97-12.25,45.9-12.25,12.23,0,24.48,2.43,35.94,7.33.22.08.43.18.65.27,3.14,1.36,6.24,2.92,9.23,4.65l128.28,74.05c28.29,16.34,45.86,46.78,45.86,79.44v148.12Z"
    />
    <path
      d="m334.79,11.63c0,.79-.15,1.53-.42,2.21-1.14,2.89-4.44,4.57-7.6,3.43-9.75-3.52-19.99-5.28-30.23-5.28-15.34,0-30.68,3.95-44.36,11.85L123.91,97.9c-27.36,15.8-44.36,45.24-44.36,76.84v68.06c0,3.31-2.69,6-6,6-1.66,0-3.16-.67-4.24-1.76s-1.76-2.59-1.76-4.24v-68.06c0-35.87,19.3-69.29,50.36-87.23L246.18,13.45c25.89-14.94,56.99-17.44,84.61-7.48,2.39.86,4,3.11,4,5.66h0Z"
    />
    <path
      d="m525.54,254.8v68.06c0,35.87-19.3,69.29-50.36,87.23l-128.27,74.06c-15.54,8.97-32.95,13.45-50.36,13.45-11.6,0-23.2-1.99-34.25-5.96-2.39-.86-4-3.12-4-5.66,0-.79.15-1.54.42-2.21.89-2.27,3.13-3.78,5.59-3.78.68,0,1.38.11,2.07.37,24.33,8.77,51.74,6.58,74.53-6.59l128.27-74.06c27.36-15.8,44.36-45.24,44.36-76.84v-68.06c0-3.31,2.69-6,6-6s6,2.69,6,6h0Z"
    />
  </svg>
</template>
<script setup></script>
