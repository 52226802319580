/* eslint-disable vue/multi-word-component-names */
import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import "./assets/Style.scss";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import { plugin as InputFacade } from "vue-input-facade";
import TrendChart from "vue-trend-chart";
import Vue3PersianDatetimePicker from "vue3-persian-datetime-picker";
const app = createApp(App);
app.use(router);
app.use(TrendChart);
app.use(InputFacade);
app.component("DatePicker", Vue3PersianDatetimePicker);
app.use(Toast, {
  transition: "Vue-Toastification__fade",
  maxToasts: 20,
  newestOnTop: true,
  rtl: true,
  icon: true,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: true,
  hideProgressBar: false,
  closeButton: "button",
});
app.mount("#app");
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  const isAuthenticated = token;
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !isAuthenticated
  ) {
    next("/");
  } else if (to.name == "Login" && isAuthenticated) {
    next("/admin");
  } else {
    next();
  }
});
