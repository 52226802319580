<template>
  <svg viewBox="0 0 376.96 470.74">
    <g>
      <path
        class="cls-1"
        d="m179.2,228.16V65.62h-5.65v160.15h-37.95V65.62h-6.45v162.54c0,1.6,1.62,3.2,3.23,3.2h44.39c1.61,0,3.24-.8,2.43-3.2Z"
      />
      <path
        class="cls-1"
        d="m159.82,278.43h-10.5c-11.3,0-20.17-8.78-20.17-19.95v-16.76c0-1.6,1.61-3.19,3.23-3.19h44.39c1.61,0,3.23,1.59,3.23,3.19v16.76c0,11.17-8.88,19.95-20.18,19.95Zm-24.22-33.52v13.57c0,7.18,6.46,13.56,13.72,13.56h10.5c7.26,0,13.72-6.38,13.72-13.56v-13.57h-37.94Z"
      />
      <path
        class="cls-1"
        d="m184.84,369.4h-59.73c-16.15,0-29.87-12.77-29.87-29.53v-40.69c-27.45-19.15-43.59-49.47-43.59-82.99,0-23.14,8.07-45.48,22.61-63.84,14.52-17.55,34.71-30.32,57.31-35.11.81,0,1.62,0,2.42.8.81.8.81,1.6.81,2.39v138.05c0,7.18,6.45,13.56,13.72,13.56h11.3c7.26,0,13.72-6.38,13.72-13.56V120.44c0-.79.8-1.59.8-2.39.81-.8,1.62-.8,2.42-.8,22.61,4.79,42.78,17.56,57.31,35.11,14.52,18.36,22.6,40.7,22.6,63.84,0,32.72-16.15,63.84-43.58,82.99v40.69c.8,15.96-12.11,29.53-28.26,29.53Zm-54.89-244.97c-41.97,11.17-71.84,48.68-71.84,92.56,0,31.12,15.34,60.64,41.97,79,.81.8,1.62,1.6,1.62,2.4v42.29c0,12.77,10.49,23.14,23.41,23.14h59.73c12.91,0,23.41-10.37,23.41-23.14v-43.09c0-.8.81-1.6,1.61-2.39,26.64-17.56,41.98-47.08,41.98-79,0-43.89-29.06-81.39-71.84-92.56v134.85c0,11.17-8.88,19.95-20.18,19.95h-10.5c-11.3,0-20.17-8.78-20.17-19.95l.8-134.06h0Z"
      />
      <path
        class="cls-1"
        d="m154.97,369.4c-1.61,0-3.23-1.6-3.23-3.19v-90.17c0-1.59,1.61-3.19,3.23-3.19s3.23,1.6,3.23,3.19v90.97c0,.79-1.61,2.39-3.23,2.39Z"
      />
      <path
        class="cls-1"
        d="m217.94,112.46q-.81,0-1.61-.79c-1.61-.8-1.61-2.4-.81-3.99l15.33-26.33c.81-1.59,2.42-1.59,4.04-.8,1.62.8,1.62,2.4.81,3.99l-15.34,26.33c-.81.8-1.61,1.59-2.42,1.59Z"
      />
      <path
        class="cls-1"
        d="m263.94,157.95c-.8,0-2.42-.8-2.42-1.6-.81-1.59,0-3.19.8-3.99l26.64-15.16c1.61-.8,3.23,0,4.04.8.8,1.6,0,3.19-.81,3.99l-26.63,15.16c-.81.8-1.62.8-1.62.8Z"
      />
      <path
        class="cls-1"
        d="m96.86,112.46c-.8,0-2.42-.79-2.42-1.59l-15.33-26.33c-.8-1.59,0-3.19.81-3.99,1.61-.79,3.23,0,4.04.8l15.34,26.33c.8,1.59,0,3.19-.81,3.99q-.8.79-1.61.79Z"
      />
      <path
        class="cls-1"
        d="m50.85,157.95q-.81,0-1.62-.8l-26.63-15.16c-1.61-.8-1.61-2.39-.81-3.99.81-1.6,2.42-1.6,4.04-.8l26.64,15.16c1.61.8,1.61,2.4.8,3.99,0,.8-.8,1.6-2.42,1.6Z"
      />
      <path
        class="cls-1"
        d="m33.9,220.18H3.23c-1.61,0-3.23-1.59-3.23-3.19s1.61-3.19,3.23-3.19h30.67c1.61,0,3.23,1.6,3.23,3.19s-1.62,3.19-3.23,3.19Z"
      />
      <path
        class="cls-1"
        d="m194.53,390.15h-69.41c-1.62,0-3.23-1.6-3.23-3.19v-20.75c0-1.6,1.61-3.19,3.23-3.19h69.41c1.61,0,3.23,1.59,3.23,3.19v20.75c0,1.59-1.61,3.19-3.23,3.19Zm-66.19-6.39h62.96v-14.36h-62.96v14.36h0Z"
      />
      <path
        class="cls-1"
        d="m184.84,410.89h-49.24c-1.61,0-3.23-1.6-3.23-3.19v-20.74c0-1.6,1.61-3.19,3.23-3.19h49.24c1.61,0,3.23,1.6,3.23,3.19v20.74c-.8,1.6-1.61,3.19-3.23,3.19Zm-46.82-6.39h43.59v-15.16h-43.59v15.16Z"
      />
      <path
        class="cls-1"
        d="m174.35,431.64h-29.06c-1.61,0-3.23-1.59-3.23-3.19v-20.75c0-1.59,1.61-3.19,3.23-3.19h29.06c1.62,0,3.23,1.6,3.23,3.19v20.75c0,1.6-1.61,3.19-3.23,3.19Zm-26.63-5.58h23.41v-15.16h-23.41v15.16Z"
      />
      <path
        class="cls-1"
        d="m271.21,470.74H36.32c-1.61,0-3.23-1.6-3.23-3.19s1.61-3.19,3.23-3.19h234.89c1.61,0,3.23,1.6,3.23,3.19s-1.61,3.19-3.23,3.19Z"
      />
      <path
        class="cls-1"
        d="m336.59,451.59h-163.05c-1.61,0-3.23-1.59-3.23-3.19s1.62-3.19,3.23-3.19h163.05c1.61,0,3.23,1.6,3.23,3.19-.81,1.6-1.62,3.19-3.23,3.19Z"
      />
      <path
        class="cls-1"
        d="m373.72,451.59h-20.17c-1.62,0-3.23-1.59-3.23-3.19s1.61-3.19,3.23-3.19h20.17c1.61,0,3.23,1.6,3.23,3.19-.81,1.6-1.62,3.19-3.23,3.19Z"
      />
      <path
        class="cls-1"
        d="m104.12,451.59H26.63c-1.61,0-3.23-1.59-3.23-3.19s1.61-3.19,3.23-3.19h77.49c1.62,0,3.23,1.6,3.23,3.19-.81,2.4-1.61,3.19-3.23,3.19Z"
      />
      <path
        class="cls-1"
        d="m271.21,398.13h-13.72c-1.61,0-2.42-.8-3.23-2.4l-3.23-23.94c-3.23-.79-7.26-1.59-10.5-3.19l-15.34,19.15c-.8.8-2.42,1.6-4.04.8l-12.1-6.38c-.81-1.6-.81-3.19-.81-3.99l8.88-22.34c-3.23-2.39-6.45-5.58-8.88-8.77,0-1.6-.81-2.4,0-3.19v-46.28c0-.8.81-1.6,1.61-2.39,21.8-14.37,36.33-37.5,40.36-62.24,0-.8.8-1.59.8-1.59l4.04-23.94c0-1.6,1.61-2.4,3.23-2.4h13.72c1.62,0,2.42.8,3.23,2.4l3.23,23.94c3.23.79,7.26,1.59,10.5,3.19l15.34-19.15c.8-.8,2.42-1.6,4.04-.8l12.11,6.38c1.61.8,1.61,2.4,1.61,3.99l-8.88,22.34c2.42,2.39,5.65,4.78,8.07,7.98l22.61-8.78c1.61-.8,3.23,0,4.04,1.59l6.45,11.97c.81,1.6.81,3.19-.8,3.99l-19.37,15.16c1.61,3.19,2.42,7.18,3.23,10.37l24.22,3.19c1.61,0,2.42,1.6,2.42,3.19v13.57c0,1.6-.8,2.39-2.42,3.19l-24.22,3.19c-.81,3.19-1.61,7.18-3.23,10.38l19.37,15.16c.8.8,1.61,2.39.8,3.99l-6.45,11.97c-.81,1.6-2.42,1.6-4.04,1.6l-22.61-8.78c-2.42,2.39-4.84,5.58-8.07,7.98l8.88,22.34c.8,1.6,0,3.19-1.61,3.99l-12.11,6.39c-1.61.79-3.23.79-4.04-.8l-15.34-21.55c-3.23,1.6-7.27,2.4-10.5,3.19l-3.23,23.94c-.81.8-2.42,2.4-4.04,2.4Zm-10.5-6.39h8.07l3.23-23.14c0-1.59,1.62-2.39,2.42-2.39,4.85-.8,8.88-1.6,13.72-3.99,1.61-.8,2.42,0,3.23.8l14.53,18.35,7.26-3.99-8.07-21.55c-.8-1.59,0-2.39.81-3.19,4.04-3.19,7.26-6.38,9.69-9.57.81-.8,2.42-1.6,3.23-.8l22.61,8.78,4.04-7.18-18.57-14.36c-.8-.8-1.61-2.39-.8-3.19,1.61-3.99,3.23-8.78,4.04-13.57,0-1.59,1.61-2.39,2.42-2.39l23.41-3.19v-7.98l-23.41-3.19c-1.61,0-2.42-1.59-2.42-2.39-.81-4.79-1.62-8.78-4.04-13.57-.81-1.59,0-2.39.8-3.19l18.57-14.36-4.04-7.18-22.61,8.77c-1.61.8-2.42,0-3.23-.79-3.23-3.99-6.46-7.18-9.69-9.58-.81-4.79-.81-5.59-.81-7.18l8.88-22.34-7.26-3.99-14.53,18.36c-.81.8-2.42,1.59-3.23.8-4.04-1.6-8.88-3.19-13.72-3.99-1.61,0-2.42-1.6-2.42-2.4l-3.23-23.14h-8.07l-4.85,23.94c0,.8-.8,1.6-.8,1.6-4.85,25.53-20.18,48.67-41.17,63.84v43.89c2.42,3.19,5.65,6.38,8.88,8.77.81.8,1.61,2.4.81,3.19l-8.88,22.34,7.27,3.99,14.52-18.35c.81-.8,2.42-1.6,3.23-.8,4.04,1.59,8.88,3.19,13.72,3.99,1.62,0,2.42,1.59,2.42,2.39l4.04,23.14Z"
      />
      <path
        class="cls-1"
        d="m96.86,377.38c-13.72,0-25.02-11.17-25.02-24.74v-32.72c-22.61-15.96-35.52-40.69-35.52-67.82,0-1.59,1.61-3.19,3.23-3.19s3.23,1.6,3.23,3.19c0,25.54,12.91,49.47,33.9,63.84.81.8,1.62,1.6,1.62,2.4v35.11c0,10.37,8.06,18.35,18.56,18.35,1.61,0,3.23,1.6,3.23,3.19s-1.61,2.39-3.23,2.39Z"
      />
    </g>
    <path
      class="cls-1"
      d="m197.63,65.62h-88.04c-1.4,0-2.79-1.38-2.79-2.76V2.76c0-1.38,1.4-2.76,2.79-2.76h88.04c1.4,0,2.79,1.38,2.79,2.76v60.09c0,2.07-1.4,2.76-2.79,2.76Zm-85.25-4.83h83.15V6.22h-83.15v54.57h0Z"
    />
    <path
      class="cls-1"
      d="m241.64,65.62h-28.65c-1.4,0-2.79-1.38-2.79-2.76V2.76c0-1.38,1.4-2.76,2.79-2.76h28.65c1.4,0,2.8,1.38,2.8,2.76v60.09c0,2.07-.7,2.76-2.8,2.76Zm-25.85-4.83h23.75V6.22h-23.75v54.57Z"
    />
    <path
      class="cls-1"
      d="m212.99,58.02h-14.67c-1.4,0-2.79-1.38-2.79-2.76V10.36c0-1.38,1.4-2.76,2.79-2.76h14.67c1.4,0,2.8,1.38,2.8,2.76v44.9c0,2.07-1.4,2.76-2.8,2.76Zm-11.88-4.83h9.09V13.81h-9.09v39.37Z"
    />
    <path
      class="cls-1"
      d="m109.03,55.95h-63.58c-.7,0-1.4-.69-2.1-1.38-.7-.69-.7-1.38,0-2.76C55.23,25.56,77.59,8.29,109.03.69c.7,0,1.4,0,2.1.69.69.69.69,1.38.69,2.07v49.73c0,1.38-1.4,2.76-2.79,2.76Zm-60.09-5.52h57.3V6.91c-26.56,6.91-45.42,21.41-57.3,43.52Z"
    />
    <path
      class="cls-1"
      d="m153.61,48.35c-8.38,0-15.37-6.91-15.37-15.19s6.99-15.2,15.37-15.2,15.37,6.91,15.37,15.2-6.99,15.19-15.37,15.19Zm0-24.87c-5.59,0-10.48,4.84-10.48,10.36s4.89,10.36,10.48,10.36,10.48-4.83,10.48-10.36c0-6.22-4.89-10.36-10.48-10.36Z"
    />
  </svg>
</template>
<script setup></script>
