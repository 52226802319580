<template>
  <svg viewBox="0 0 457.53 446.19">
    <path
      class="cls-1"
      d="m382.15,117.64c15.88,0,28.76,12.88,28.76,28.76v153.39c0,15.88-12.88,28.76-28.76,28.76s-28.76-12.88-28.76-28.76v-153.39c0-15.88,12.88-28.76,28.76-28.76Z"
    />
    <path
      class="cls-2"
      d="m228.76,2.6c15.88,0,28.76,12.88,28.76,28.76v383.47c0,15.88-12.88,28.76-28.76,28.76s-28.76-12.88-28.76-28.76V31.36c0-15.88,12.88-28.76,28.76-28.76Z"
    />
    <path
      class="cls-1"
      d="m75.38,117.64c15.88,0,28.76,12.88,28.76,28.76v153.39c0,15.88-12.88,28.76-28.76,28.76s-28.76-12.88-28.76-28.76v-153.39c0-15.88,12.88-28.76,28.76-28.76Z"
    />
  </svg>
</template>
<script setup></script>
