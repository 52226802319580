<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <transition name="modal-fade">
    <div
      v-if="show"
      :class="size === 'full' ? 'modal-container-full' : 'modal-container'"
    >
      <div class="modal-overlay" @click="closeModal"></div>
      <div
        :class="
          size === 'sm'
            ? 'modal-content-sm headerclose'
            : size === 'md'
            ? 'modal-content-md headerclose'
            : size === 'lg'
            ? 'modal-content-lg headerclose'
            : size === 'full'
            ? 'modal-content-full headerclose'
            : 'modal-content headerclose'
        "
      >
        <div class="modal-body">
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { ref, onMounted, onUnmounted } from "vue";

// eslint-disable-next-line no-unused-vars

export default {
  setup() {
    const show = ref(false);
    const openModal = () => {
      show.value = true;
      document.body.style.overflow = "hidden";
    };
    const closeModal = () => {
      show.value = false;
      document.body.style.overflow = "";
    };
    const closeModalOnEscape = (event) => {
      if (event.key === "Escape") {
        closeModal();
      }
    };
    onMounted(() => {
      document.addEventListener("keydown", closeModalOnEscape);
    });

    onUnmounted(() => {
      document.removeEventListener("keydown", closeModalOnEscape);
    });
    return { show, openModal, closeModal };
  },
  props: {
    size: String,
  },
};
</script>
<style scoped lang="scss">
[data-theme="dark"] {
  .close-button {
    svg {
      fill: black;
    }
  }
}

.close-button {
  cursor: pointer;
  position: absolute;
  left: 15px;
  top: 10px;
  z-index: 20000;

  svg {
    width: 15px;
  }
}

.headerclose {
  position: relative;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.modal-container-full {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;

  .modal-body {
    text-align: start;
  }
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px); /* Adjust blur effect */
}

.modal-content {
  background-color: white;
  width: 80%;
  padding: 20px;
  border-radius: 8px;
  z-index: 1000;
}

.modal-content-sm {
  background-color: white;
  width: 30%;
  padding: 20px;
  border-radius: 8px;
  z-index: 1000;
}

.modal-content-md {
  background-color: white;
  width: 45%;
  padding: 20px;
  border-radius: 8px;
  z-index: 1000;
}

.modal-content-lg {
  background-color: white;
  width: 60%;
  padding: 20px;
  border-radius: 8px;
  z-index: 1000;
}

.modal-content-full {
  background-color: white;
  width: 95%;
  height: 95%;
  padding: 24px;
  border-radius: 8px;
  z-index: 1000;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.2s;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

@media only screen and (max-width: 1025px) {
  .modal-content-md {
    width: 60%;
  }
}

@media only screen and (max-width: 768px) {
  .modal-content {
    width: 80%;
  }
  .modal-content-sm {
    width: 70%;
    font-size: 0.9rem;
  }
  .modal-content-md {
    width: 85%;
  }
  .modal-content-lg {
    width: 85%;
  }
  .modal-content-full {
    background-color: white;
    width: 95%;
    height: 85%;
    padding: 20px;
    border-radius: 8px;
    z-index: 1000;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
</style>
