<template>
  <svg viewBox="0 0 372.52 495.53">
    <g
      id="_455_Article_blog_story_typewriter_writer"
      data-name="455, Article, blog, story, typewriter, writer"
    >
      <g>
        <path
          d="m314.89,431.51H51.56c-8.1,0-16.2-3.34-21.61-9.35-4.73-5.34-6.75-11.35-6.08-18.02l18.23-152.85c2.03-16.02,13.5-26.7,27.68-26.7h226.19c14.18,0,25.66,11.34,27.68,26.7l18.23,152.85c.68,6.67-1.35,12.68-6.07,18.02-4.73,6.01-12.83,9.35-20.93,9.35ZM70.46,229.26c-11.48,0-20.93,9.35-22.28,22.7l-18.23,152.85c-.67,4.67,1.35,10.01,4.73,14.01,4.73,4.67,10.81,8.01,17.56,8.01h263.33c6.75,0,13.51-2.67,17.56-8.01,3.38-4,5.4-9.35,4.73-14.01l-18.23-152.85c-1.35-13.35-10.8-22.7-22.28-22.7H70.46Z"
        />
        <path
          d="m183.22,291.34c-33.76,0-61.44-27.37-61.44-60.74V77.08c0-1.34,1.35-2.67,2.7-2.67h118.16c1.35,0,2.7,1.33,2.7,2.67v153.52c-.67,33.37-28.35,60.74-62.12,60.74Zm-56.72-211.59v150.85c0,30.71,25.66,56.07,56.72,56.07s56.72-25.36,56.72-56.07V79.75h-113.44Z"
        />
        <path
          d="m183.22,308.03c-42.54,0-76.97-34.05-76.97-76.1v-56.07c0-1.33,1.35-2.67,2.7-2.67s2.7,1.34,2.7,2.67v56.07c0,39.38,32.41,71.42,72.25,71.42s72.25-32.04,72.25-71.42v-56.07c0-1.33,1.35-2.67,2.7-2.67s2.7,1.34,2.7,2.67v56.07c-1.35,42.05-35.79,76.1-78.32,76.1Z"
        />
        <path
          d="m285.86,229.26c-1.36,0-2.7-1.33-2.7-2.67v-85.44c0-1.34,1.35-2.67,2.7-2.67s2.7,1.34,2.7,2.67v85.44c-.68,1.34-1.35,2.67-2.7,2.67Z"
        />
        <path
          d="m79.24,229.26c-1.35,0-2.7-1.33-2.7-2.67v-85.44c0-1.34,1.35-2.67,2.7-2.67s2.7,1.34,2.7,2.67v85.44c0,1.34-1.35,2.67-2.7,2.67Z"
        />
        <path
          d="m285.86,195.22H79.24c-1.35,0-2.7-1.34-2.7-2.67s1.35-2.67,2.7-2.67h205.94c1.35,0,2.7,1.34,2.7,2.67s-.67,2.67-2.02,2.67Z"
        />
        <path
          d="m331.77,209.91H33.32c-1.35,0-2.7-1.34-2.7-2.67s1.35-2.67,2.7-2.67h297.77c1.35,0,2.7,1.33,2.7,2.67s-.67,2.67-2.03,2.67Z"
        />
        <path
          d="m343.92,230.6h-12.16c-1.35,0-2.7-1.34-2.7-2.67v-40.72c0-1.34,1.35-2.67,2.7-2.67h12.16c1.35,0,2.7,1.33,2.7,2.67v40.72c0,1.33-1.35,2.67-2.7,2.67Zm-10.13-5.34h7.43v-35.37h-7.43v35.37Z"
        />
        <path
          d="m356.75,220.58h-12.15c-1.36,0-2.71-1.34-2.71-2.67v-21.36c0-1.34,1.35-2.67,2.71-2.67h12.15c1.35,0,2.7,1.34,2.7,2.67v22.03c-.67,1.34-1.35,2-2.7,2Zm-10.13-4.67h7.43v-16.69h-7.43v16.69Z"
        />
        <path
          d="m33.32,230.6h-12.15c-1.36,0-2.7-1.34-2.7-2.67v-40.72c0-1.34,1.35-2.67,2.7-2.67h12.15c1.35,0,2.7,1.33,2.7,2.67v40.72c0,1.33-1.35,2.67-2.7,2.67Zm-9.45-5.34h7.42v-35.37h-7.42v35.37Z"
        />
        <path
          d="m21.17,220.58h-12.83c-1.35,0-2.7-1.34-2.7-2.67v-21.36c0-1.34,1.35-2.67,2.7-2.67h12.83c1.35,0,2.7,1.34,2.7,2.67v22.03c0,1.34-1.35,2-2.7,2Zm-10.13-4.67h7.42v-16.69h-7.42v16.69Z"
        />
        <path
          d="m302.06,422.16H64.38c-7.42,0-14.86-3.34-19.58-8.68-4.05-4.67-6.08-10.68-5.4-16.69l16.2-138.17c2.03-14.69,12.16-24.7,24.98-24.7h43.22c1.35,0,2.7.67,2.7,2,2.7,28.71,27.01,50.06,56.04,50.06s53.34-21.35,56.04-50.06c0-1.33,1.36-2,2.71-2h43.21c12.83,0,23.64,10.01,24.98,24.7l16.88,138.17c.67,6-1.35,11.35-5.4,16.69s-11.48,8.68-18.9,8.68ZM81.27,238.61c-10.13,0-18.91,8.01-20.26,20.02l-16.88,138.17c-.68,4.67.67,8.68,4.05,12.68,4.05,4.67,10.13,7.35,15.53,7.35h237.67c6.08,0,12.16-2.67,15.53-7.35,3.38-3.34,4.73-8,4.05-12.68l-15.53-138.17c-1.36-12.01-9.46-20.02-20.26-20.02h-41.19c-2.03,14.01-8.78,26.7-19.58,36.71-11.48,10.01-26.33,16.02-41.19,16.02s-29.71-5.35-41.18-16.02c-10.81-9.35-17.56-22.7-19.58-36.71h-41.19Z"
        />
        <path
          d="m184.57,96.44h-40.51c-1.35,0-2.7-1.34-2.7-2.67s1.35-2.67,2.7-2.67h40.51c1.36,0,2.71,1.33,2.71,2.67s-1.35,2.67-2.71,2.67Z"
        />
        <path
          d="m206.18,118.46h-61.44c-1.35,0-2.7-1.34-2.7-2.67s1.35-2.67,2.7-2.67h61.44c1.35,0,2.71,1.34,2.71,2.67s-1.36,2.67-2.71,2.67Z"
        />
        <path
          d="m221.71,137.15h-77.65c-1.35,0-2.7-1.34-2.7-2.67s1.35-2.67,2.7-2.67h76.97c1.35,0,2.71,1.34,2.71,2.67s-.68,2.67-2.03,2.67Z"
        />
        <path
          d="m188.62,155.84h-44.57c-1.35,0-2.7-1.33-2.7-2.67s1.35-2.67,2.7-2.67h44.57c1.35,0,2.7,1.34,2.7,2.67s-1.35,2.67-2.7,2.67Z"
        />
        <path
          d="m221.71,174.53h-77.65c-1.35,0-2.7-1.34-2.7-2.67s1.35-2.67,2.7-2.67h76.97c1.35,0,2.71,1.34,2.71,2.67.67,1.34-.68,2.67-2.03,2.67Z"
        />
        <path
          d="m81.94,336.73h-22.96c-1.35,0-2.7-1.34-2.7-2.67,0-9.34,6.08-17.35,14.18-17.35,7.43,0,13.51,7.34,14.18,16.69h0c0,2.67-1.35,3.34-2.71,3.34Zm-20.25-4.67h17.55c-.68-5.34-4.73-9.35-8.78-9.35s-8.1,4.01-8.77,9.35Z"
        />
        <path
          d="m119.08,336.73h-22.96c-1.36,0-2.7-1.34-2.7-2.67,0-9.34,6.08-17.35,14.18-17.35,7.42,0,13.5,7.34,14.18,16.69h0c0,2.67-1.35,3.34-2.7,3.34Zm-20.26-4.67h17.55c-.67-5.34-4.73-9.35-8.77-9.35s-7.43,4.01-8.78,9.35Z"
        />
        <path
          d="m156.21,336.73h-22.96c-1.35,0-2.7-1.34-2.7-2.67,0-9.34,6.08-17.35,14.18-17.35,7.43,0,13.51,7.34,14.18,16.69h0c0,2.67-1.35,3.34-2.7,3.34Zm-19.58-4.67h17.55c-.68-5.34-4.73-9.35-8.78-9.35s-8.1,4.01-8.77,9.35Z"
        />
        <path
          d="m194.03,336.73h-22.96c-1.35,0-2.71-1.34-2.71-2.67,0-9.34,6.08-17.35,14.18-17.35,7.42,0,13.5,7.34,14.18,16.69h0c-.68,2.67-1.35,3.34-2.7,3.34Zm-20.26-4.67h17.55c-.67-5.34-4.73-9.35-8.77-9.35s-8.1,4.01-8.78,9.35Z"
        />
        <path
          d="m231.16,336.73h-22.96c-1.35,0-2.7-1.34-2.7-2.67,0-9.34,6.08-17.35,14.18-17.35,7.42,0,13.51,7.34,14.18,16.69h0c0,2.67-1.35,3.34-2.7,3.34Zm-20.26-4.67h17.56c-.68-5.34-4.73-9.35-8.78-9.35s-8.1,4.01-8.78,9.35Z"
        />
        <path
          d="m268.3,336.73h-22.96c-1.35,0-2.7-1.34-2.7-2.67,0-9.34,6.08-17.35,14.18-17.35,7.43,0,13.51,7.34,14.18,16.69h0c0,2.67-1.36,3.34-2.7,3.34Zm-20.25-4.67h17.55c-.68-5.34-4.73-9.35-8.78-9.35s-7.42,4.01-8.77,9.35Z"
        />
        <path
          d="m305.44,336.73h-22.96c-1.36,0-2.71-1.34-2.71-2.67,0-9.34,6.08-17.35,14.18-17.35,7.42,0,13.5,7.34,14.18,16.69h0c0,2.67-1.35,3.34-2.7,3.34Zm-19.58-4.67h17.55c-.68-5.34-4.73-9.35-8.78-9.35-4.73,0-8.1,4.01-8.77,9.35Z"
        />
        <path
          d="m81.94,366.09h-22.96c-1.35,0-2.7-1.33-2.7-2.67,0-9.35,6.08-17.36,14.18-17.36,7.43,0,13.51,7.35,14.18,16.69h0c0,2.67-1.35,3.34-2.71,3.34Zm-20.25-4.67h17.55c-.68-5.35-4.73-9.35-8.78-9.35s-8.1,4-8.77,9.35Z"
        />
        <path
          d="m119.08,366.09h-22.96c-1.36,0-2.7-1.33-2.7-2.67,0-9.35,6.08-17.36,14.18-17.36,7.42,0,13.5,7.35,14.18,16.69h0c0,2.67-1.35,3.34-2.7,3.34Zm-20.26-4.67h17.55c-.67-5.35-4.73-9.35-8.77-9.35s-7.43,4-8.78,9.35Z"
        />
        <path
          d="m156.21,366.09h-22.96c-1.35,0-2.7-1.33-2.7-2.67,0-9.35,6.08-17.36,14.18-17.36,7.43,0,13.51,7.35,14.18,16.69h0c0,2.67-1.35,3.34-2.7,3.34Zm-19.58-4.67h17.55c-.68-5.35-4.73-9.35-8.78-9.35s-8.1,4-8.77,9.35Z"
        />
        <path
          d="m194.03,366.09h-22.96c-1.35,0-2.71-1.33-2.71-2.67,0-9.35,6.08-17.36,14.18-17.36,7.42,0,13.5,7.35,14.18,16.69h0c-.68,2.67-1.35,3.34-2.7,3.34Zm-20.26-4.67h17.55c-1.35-5.35-4.73-9.35-8.77-9.35s-8.1,4-8.78,9.35Z"
        />
        <path
          d="m231.16,366.09h-22.96c-1.35,0-2.7-1.33-2.7-2.67,0-9.35,6.08-17.36,14.18-17.36,7.42,0,13.51,7.35,14.18,16.69h0c0,2.67-1.35,3.34-2.7,3.34Zm-20.26-4.67h17.56c-.68-5.35-4.73-9.35-8.78-9.35s-8.1,4-8.78,9.35Z"
        />
        <path
          d="m268.3,366.09h-22.96c-1.35,0-2.7-1.33-2.7-2.67,0-9.35,6.08-17.36,14.18-17.36,7.43,0,13.51,7.35,14.18,16.69h0c0,2.67-1.36,3.34-2.7,3.34Zm-20.25-4.67h17.55c-.68-5.35-4.73-9.35-8.78-9.35s-7.42,4-8.77,9.35Z"
        />
        <path
          d="m305.44,366.09h-22.96c-1.36,0-2.71-1.33-2.71-2.67,0-9.35,6.08-17.36,14.18-17.36,7.42,0,13.5,7.35,14.18,16.69h0c0,2.67-1.35,3.34-2.7,3.34Zm-19.58-4.67h17.55c-.68-5.35-4.73-9.35-8.78-9.35-4.73,0-8.1,4-8.77,9.35Z"
        />
        <path
          d="m81.94,395.47h-22.96c-1.35,0-2.7-1.34-2.7-2.67,0-9.34,6.08-17.35,14.18-17.35,7.43,0,13.51,7.34,14.18,16.69h0c0,2.67-1.35,3.34-2.71,3.34Zm-20.25-4.67h17.55c-.68-5.34-4.73-9.34-8.78-9.34s-8.1,4-8.77,9.34Z"
        />
        <path
          d="m119.08,395.47h-22.96c-1.36,0-2.7-1.34-2.7-2.67,0-9.34,6.08-17.35,14.18-17.35,7.42,0,13.5,7.34,14.18,16.69h0c0,2.67-1.35,3.34-2.7,3.34Zm-20.26-4.67h17.55c-.67-5.34-4.73-9.34-8.77-9.34s-7.43,4-8.78,9.34Z"
        />
        <path
          d="m156.21,395.47h-22.96c-1.35,0-2.7-1.34-2.7-2.67,0-9.34,6.08-17.35,14.18-17.35,7.43,0,13.51,7.34,14.18,16.69h0c0,2.67-1.35,3.34-2.7,3.34Zm-19.58-4.67h17.55c-.68-5.34-4.73-9.34-8.78-9.34-4.73,0-8.1,4-8.77,9.34Z"
        />
        <path
          d="m194.03,395.47h-22.96c-1.35,0-2.71-1.34-2.71-2.67,0-9.34,6.08-17.35,14.18-17.35,7.42,0,13.5,7.34,14.18,16.69h0c-.68,2.67-1.35,3.34-2.7,3.34Zm-20.26-4.67h17.55c-.67-5.34-4.73-9.34-8.77-9.34s-8.1,4-8.78,9.34Z"
        />
        <path
          d="m231.16,395.47h-22.96c-1.35,0-2.7-1.34-2.7-2.67,0-9.34,6.08-17.35,14.18-17.35,7.42,0,13.51,7.34,14.18,16.69h0c0,2.67-1.35,3.34-2.7,3.34Zm-20.26-4.67h17.56c-.68-5.34-4.73-9.34-8.78-9.34s-8.1,4-8.78,9.34Z"
        />
        <path
          d="m268.3,395.47h-22.96c-1.35,0-2.7-1.34-2.7-2.67,0-9.34,6.08-17.35,14.18-17.35,7.43,0,13.51,7.34,14.18,16.69h0c0,2.67-1.36,3.34-2.7,3.34Zm-20.25-4.67h17.55c-.68-5.34-4.73-9.34-8.78-9.34s-7.42,4-8.77,9.34Z"
        />
        <path
          d="m305.44,395.47h-22.96c-1.36,0-2.71-1.34-2.71-2.67,0-9.34,6.08-17.35,14.18-17.35,7.42,0,13.5,7.34,14.18,16.69h0c0,2.67-1.35,3.34-2.7,3.34Zm-19.58-4.67h17.55c-.68-5.34-4.73-9.34-8.78-9.34-4.73,0-8.1,4-8.77,9.34Z"
        />
        <path
          d="m259.52,448.86H16.44c-1.35,0-2.7-1.34-2.7-2.67s1.35-2.67,2.7-2.67h243.08c1.35,0,2.7,1.34,2.7,2.67s-1.35,2.67-2.7,2.67Z"
        />
        <path
          d="m327.04,463.55h-168.13c-1.35,0-2.7-1.34-2.7-2.67s1.35-2.67,2.7-2.67h168.13c1.36,0,2.71,1.33,2.71,2.67s-1.35,2.67-2.71,2.67Z"
        />
        <path
          d="m365.53,463.55h-20.93c-1.36,0-2.71-1.34-2.71-2.67s1.35-2.67,2.71-2.67h20.93c1.35,0,2.7,1.33,2.7,2.67-.67,1.34-1.35,2.67-2.7,2.67Z"
        />
        <path
          d="m86.66,462.88H6.99c-1.35,0-2.7-1.33-2.7-2.67s1.35-2.67,2.7-2.67h79.67c1.36,0,2.7,1.34,2.7,2.67s-1.35,2.67-2.7,2.67Z"
        />
      </g>
    </g>
  </svg>
</template>
<script setup></script>
